import AXIOS from 'axios';
import { BUSINESS_ID } from 'shared/constants/AppConstants';
import Parameters from '../parameters';
import { API_CONFIG } from './config';
import dayjs from "dayjs";

type Server = "distribution" | "common" | "lender";

function getProtectedInstanceFor(server: Server = "distribution") {
    let apiUrl = Parameters.ApiUrlOne;
    if (server == "common") {
        apiUrl = Parameters.ApiUrlTwo;
    } else if (server == "lender") {
        apiUrl = Parameters.ApiUrlThree;
    }
    
    const headers: any = {};
    const user = localStorage.getItem('user');
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
    }
    return AXIOS.create({
        //@ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl + '/',
        headers: {
            'Accept': 'application/json',
            "Content-Type": 'application/json',
            ...headers,
        }
    })
}

function getPublicInstance(firstServer = true) {
    const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo
    console.log('dff', firstServer, apiUrl)
    const headers = {};
    return AXIOS.create({
        // @ts-ignore 
        'accept': 'application/json',
        'baseURL': apiUrl + '/',
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}

function getProtectedInstanceForFinbox(headers = {}) {
    return AXIOS.create({
      // @ts-ignore
      accept: "application/json",
      // 'baseURL': apiUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        ...headers,
      },
    });
}
  
function getProtectedInstance(firstServer = true) {
    const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}

function getProtectedInstanceForBajajHousingFinance(thirdServer = true) {
    const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = thirdServer ? Parameters.ApiUrlThree : Parameters.ApiUrlThree
    if (user) {
        // headers['api_key'] = JSON.parse(user as string).apikey;
        // headers['api_id'] = JSON.parse(user as string).apiId;
        headers['api_key'] = "6e4cf833714519ab7d05d40d37ebb4c1";
        headers['api_id'] = "e67fe92d884c2f9460f1744300739f1b";
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}


function getProtectedInstanceForDownload(firstServer = true) {
    const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
    }
    return AXIOS.create({
        // @ts-ignore
        // 'method': 'GET',
        'accept': 'application/octet-stream',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/octet-stream',
            ...headers,
        },
        responseType: 'blob',
    });
}

function getProtectedInstanceForTherdServer(thirdServer = true) {
    const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = thirdServer ? Parameters.ApiUrlThree : Parameters.ApiUrlTwo
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
        // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}

function getPubslicInstanceForTherdServer(thirdServer = true) {
    // const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = thirdServer ? Parameters.ApiUrlThree : Parameters.ApiUrlTwo
    // if (user) {
    // headers['api_key'] = JSON.parse(user as string).apikey;
    // headers['api_id'] = JSON.parse(user as string).apiId;
    headers['api_key'] = "6743e0681860ce20c8d98b7fcd76bd4b";
    headers['api_id'] = "16e40d1ae463ca1fb138da75c41f3856";
    //         api_id:16e40d1ae463ca1fb138da75c41f3856
    // api_key:6743e0681860ce20c8d98b7fcd76bd4b
    // }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}

function getProtectedInstanceForBlob(firstServer = true) {
    const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
        // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
        responseType: 'blob'
    });
}


function handleErrors(error: any) {
    let message = 'Something went wrong!!';
    if (error && error.response && error?.response?.error) {
        let parsedError: any = '';
        message = error.response.data.error;
        if (typeof error.response.data.error === 'string') {
            try {
                parsedError = JSON.parse(error.response.data.error);
                if (parsedError['errorMessage']) {
                    message = parsedError['errorMessage'];
                } else {
                    const allErrors = Array();
                    for (let i in parsedError) {
                        allErrors.push(parsedError[i]);
                    }
                    message = allErrors.join(', ');
                }
            } catch (e) {
            }
        }

    }
    else if (error?.response?.data?.payload?.errorMessage) {
        message = error.response.data.payload.errorMessage;
        let arrMessage = message.split(" {")
        if ((arrMessage?.length ?? 0) <= 1) {
            arrMessage = message.split("400{");
        }
        if ((arrMessage?.length ?? 0) <= 1) {
            arrMessage = message.split("403{");
        }
        if ((arrMessage?.length ?? 0) > 1) {
            const objMessage = JSON.parse("{" + arrMessage[1])
            if (objMessage?.errors) {
                if ((objMessage?.errors?.length ?? 0) > 0) {
                    let msg = '';
                    objMessage?.errors?.filter((item: any) => {
                        msg = item.errorCodeDescription + ',' + msg;
                    })
                    message = msg ?? msg
                }
            }
            message = objMessage?.message ?? message
        }
    }
    else if (error && error.response && error.response.data && error.response?.data?.message) {
        return message = error.response.data.message;
    } 
    else if (error?.payload?.errorMessage) {
        return message = error?.payload?.errorMessage;
    }
    else if (error && error.response && error.response.data && error?.response?.data?.errors) {
        let e = Array();
        for (let i in error.response?.data?.errors) {
            e.push(`${i}: ${error.response.data.errors[i]}`);
        }
        message = e.join(',');
    } else if (typeof error === 'string') {
        message = error;
    }
    return message;
}

const get_BUSINESS_ID = () => {
    const user = localStorage.getItem('user');
    if (user) {
        const businessId = JSON.parse(user as string)?.businessId
        return businessId ?? BUSINESS_ID
    }
    return BUSINESS_ID
}

async function getIPData() {
    return await AXIOS.get("https://api.ipify.org/?format=json");
};

async function login(payload: object) {
    const instance = getPublicInstance(false);
    return await instance.post(API_CONFIG.login, payload);
}

async function checkRecaptchaResponse(payload: any) {
    const instance = getPublicInstance(false);
    return await instance.post(API_CONFIG.checkRecaptchaResponse, payload);
}

async function checkPhone(mobile: number) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.checkPhone + mobile);
}

async function verifyOtp(payload: any) {
    const instance = getProtectedInstance(false);
    return await instance.post(API_CONFIG.verifyOtp, payload);
}

async function getTenantDocumentDetails() {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.tenantDocDetails);
}

async function getTypes() {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(API_CONFIG.enumValues);
}


async function updateConnectorBusinessId(payload?: any) {
    const instance = getProtectedInstance();
    return await instance.put(API_CONFIG.updateConnectorBusinessId, payload);
}

async function getBusinessIdUpdate(businessId?: string) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.updateBusinessId + businessId);
}

async function getLendersByType(loanType: string) {
    const instance = getProtectedInstanceForTherdServer();
    return await instance.get(`dsa/lender/${loanType}/list`);
}

async function searchCustomers(text?: string) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.searchCustomers + (text ?? ""));
}
async function getConnector(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.connectorLists, { params });
}
async function getConnectors(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.connectorList, { params });
}
async function getConnectorById(id: number) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.connectorById + "/" + id);
}
async function getSubConnectors(connectorId?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.subConnectorList + connectorId);
}

async function checkPanDetails(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.panDetails, { params });
}

async function checkPanDetailsForProfile(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.panDetailsForProfile, { params });
}

async function panVerify(data?: any) {
    const instance = getProtectedInstance(false);
    return await instance.post("verify/pan", data)
}

async function getPanVerify(params?: any){
    const instance = getProtectedInstance(false);
    return await instance.get("external/v1/get/pan/verification", {params})
}


async function checkPincode(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.pinDetails, { params });
}

async function checkPincodeForBajaj(params?: any) {
    const instance = getProtectedInstanceForBajajHousingFinance(false);
    return await instance.get("dsa/lender/bhfl/get/city", { params });
}

async function addLead(params?: any) {
    let url = params.applicationType !== 'INDIVIDUAL' ? API_CONFIG.addLeadBusiness : API_CONFIG.addLeadIndividual;
    const instance = getProtectedInstance();
    return await instance.post(url, params);
}

async function getLeadAdditional(pan: string) {
    let url = API_CONFIG.getLeadAdditional + pan;
    const instance = getProtectedInstance();
    return await instance.get(url);
}

async function editLead(caseId: string, payload?: any) {
    let url = `${API_CONFIG.editLead}${caseId}`;
    const instance = getProtectedInstance();
    return await instance.post(url, payload);
}

async function getLeads(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.leadList, { params });
}

async function getLeadsByFilter(page, pageSize, params?: any) {
    const instance = getProtectedInstance();
    return await instance.post(`${API_CONFIG.leadListbyFilter}?loggedInFrom=web&pageNo=${page}&pageSize=${pageSize}`, params);
}

async function getLeadsByFilterPostCase(lenderStatus: any, pageNo, pageSize, params?: any) {
    const instance = getProtectedInstanceForTherdServer();
    return await instance.post('dsa/lender/' + lenderStatus + '/filter/cases/list?loggedInFrom=web&pageNo=' + pageNo + '&pageSize=' + pageSize, params);
}

async function getBureauScore(pan: string) {
    const instance = getProtectedInstance();
    return await instance.get(`/bureau/bureauScore/status?panId=${pan}`)
}

async function getConsentInfo(payload: any) {
    const instance = getProtectedInstance();
    return await instance.post('bureau/getConsent', payload)
}

async function checkExpireyLink(uniqueId: any) {
    const instance = getProtectedInstance();
    return await instance.post('bureau/free/link/expiry', uniqueId)
}

async function updateConsent(uniqueId: any) {
    const instance = getProtectedInstance();
    return await instance.put('bureau/free/updateConsent', uniqueId)
}

async function genrateBureauScore(payload: any) {
    const instance = getProtectedInstance();
    return await instance.post('bureau/generate/bureauScore', payload)
}

async function getHeirarchyUsers(userId) {
    const instance = getProtectedInstance();
    return await instance.get(`${API_CONFIG.heirarchyUsers}${userId}`);
}

async function getLeadsByLender(lenderStatus: any, params: any) {
    const instance = getProtectedInstanceForTherdServer();
    return await instance.get('dsa/lender/' + lenderStatus + '/cases/list', { params });
}

async function getLeadsById(id?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.leadListById+ id + `?workFlowType=ASSISTED`);
}

async function getLeadList(mobile: String, params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.leadListMobile + "/" + mobile, { params });
}

async function getBreStatus(Id: number) {
    const instance = getProtectedInstance();
    return await instance.get(`offers/case/${Id}`);
}

async function addConnector(params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.addConnector, params);
}

async function updateConnector(id: any, params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.updateConnector + "/" + id, params);
}

async function updateConnectorStatus(id: any, params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.updateConnectorStatus + "/" + id, params);
}

async function addDepartment(params: any) {
    const instance = getProtectedInstance(true);
    return await instance.post(API_CONFIG.addDepartment + '/' + get_BUSINESS_ID() + '/departments', params);
}

async function getDepartments(params?: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(API_CONFIG.listDepartment + '/' + get_BUSINESS_ID() + '/departments', { params });
}

async function addDesignations(params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.addDesignation + '/' + get_BUSINESS_ID() + '/designations', params);
}

async function getDesignations(params?: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(API_CONFIG.listDesignation + '/' + get_BUSINESS_ID() + '/designations', { params });
}

async function addLocations(params: any) {
    const instance = getProtectedInstance(true);
    return await instance.post(API_CONFIG.addLocation + '/' + get_BUSINESS_ID() + '/locations', params);
}

async function addLoanProduct(params: any) {
    const instance = getProtectedInstance(true);
    return await instance.post(API_CONFIG.addLoanProduct + '/' + get_BUSINESS_ID() + '/loan_product', params);
}

async function getLoanProduct(params?: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(API_CONFIG.listLoanProduct + '/' + get_BUSINESS_ID() + '/loan_product', { params });
}

async function getLocations(params?: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(API_CONFIG.listLocation + '/' + get_BUSINESS_ID() + '/locations', { params });
}

async function fileUpload(params: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(API_CONFIG.fileUploadfree, params);
}

async function uploadDocument(params: any, documentSetId: any) {
    const instance = getProtectedInstance(false);
    return await instance.post(API_CONFIG.uploadDocument + `/${documentSetId}`, {}, { params });
}

async function getDocumentHierarchyList(params: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.documentHierarchyListfree, { params });
}

async function getCaseDetailsByShareLinkId(shareLinkId: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.caseDetailByShareLinkId + shareLinkId);
}

async function addDocument(params: any, documentSetId: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(API_CONFIG.addDocumentfree + documentSetId, params, {});
}

async function getDocumentListFree(documentSetId: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.documentListfree + documentSetId + '/list');
}

async function getDocumentList(documentSetId: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.documentList + documentSetId + '/list');
}

async function getReplaceDocumentList(documentSetId: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.documentList + documentSetId + '/replace/list');
}

async function deleteDocument(businessId: any, documentSetId: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(API_CONFIG.deleteDoc + businessId + '/' + documentSetId);
}


async function getLenders(params?: any) {
    const instance = getProtectedInstanceFor("lender");
    return await instance.get("dsa/" + API_CONFIG.lenderList, { params });
}

async function getBasicOfferByCaseId(caseId: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.basicOffersByCaseId + caseId);
}

async function getPaySubConnector(connecterID, params?: any) {
    const instance = getProtectedInstance();
    return await instance.get("connector/" + `${connecterID}` + API_CONFIG.paySubConnector, { params });
}

async function updateBankInfoFlagForConnectors(payload: any) {
    const instance = getProtectedInstance();
    return await instance.put(API_CONFIG.paySubConnectorUpdate, payload);
}

async function addLender(params: any) {
    const instance = getProtectedInstanceFor("lender");
    return await instance.post(API_CONFIG.addLender, params);
}

async function updateLender(params: any, lenderInfoId: any) {
    const instance = getProtectedInstanceFor("lender");
    return await instance.post(`dsa/lender/${lenderInfoId}/update`, params);
}

async function addInsurance(params: any) {
    const instance = getProtectedInstanceFor('distribution');
    return await instance.post("insurance", params);
}

async function getInsurance(params: any) {
    const instance = getProtectedInstanceFor("distribution");
    return await instance.get("insurance", { params });
}

async function updateInsuranceStatus(insuranceInfoId: string, status: boolean) {
    const instance = getProtectedInstanceFor("distribution");
    return await instance.put(`insurance/update/status?insuranceInfoId=${insuranceInfoId}&status=${status}`);
}

async function updateProductPlan(insuranceInfoId: string, params: any) {
    const instance = getProtectedInstanceFor("distribution");
    return await instance.put(`insurance/update/product-plan?insuranceInfoId=${insuranceInfoId}`, params);
}

async function getLenderListInsurer(params: any) {
    const instance = getProtectedInstanceFor("lender");
    return await instance.get("dsa/lender/list/insurer", { params });
}

async function getFiList(params?: any) {
    const instance = getProtectedInstanceFor("lender");
    return await instance.get(API_CONFIG.fiList, { params });
}

async function getProductPlanList(params?: any) {
    const instance = getProtectedInstanceFor("common");
    return await instance.get("product-plan", { params });
}

async function getEmployees(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.employeList, { params });
}

async function addOfferOrNotice(params: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(
        API_CONFIG.addOfferOrNoticeOrGreeting + '/' + get_BUSINESS_ID(),
        params,
    );
}

async function addGreetings(params: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(
        API_CONFIG.addGreetings + '/' + get_BUSINESS_ID(),
        params,
    );
}

async function getOffers(params?: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(
        API_CONFIG.listOffer + '/' + get_BUSINESS_ID() + '/OFFER',
        { params },
    );
}

async function getNotices(params?: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(
        API_CONFIG.listOffer + '/' + get_BUSINESS_ID() + '/NOTICE',
        { params },
    );
}

async function getGreetings(params?: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(
        API_CONFIG.listOffer + '/' + get_BUSINESS_ID() + '/GREETING',
        { params },
    );
}

async function getAllNotificationList(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.allNotificationList, { params });
}
async function getNotificationList(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.notificationList, { params });
}

async function updateNotificationStatus(notificationId) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.updateNotificationStatus + '/' + `${notificationId}`);
}

async function getUnreadNotificationCnt() {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.unreadNotificationCnt);
}

async function clearAllNotifications() {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.clearAllNotifications);
}

async function getManager(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.searchuser);
}

async function getUsersWithRole(params?: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(`${API_CONFIG.usersWithRole}`, { params });
}

async function addEmployee(params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.addemployee, params);
}

async function getBureauCredits(userId) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.bureauCredits + "userId=" + `${userId}`);
}

async function updateEmployee(params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.updateemployee, params);
}

async function getAppContent(type: String, params?: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(
        API_CONFIG.listAppContent + 'BU6098926543/' + type,
        { params },
    );
}

async function getAppContentFAQ(type: String, params: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(
        API_CONFIG.listAppContentFAQ + 'BU6098926543/' + "FAQ?pageNo=1&pageSize=100",
        { params },
    );
}

async function addAppContent(params: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(
        API_CONFIG.addAppContent + get_BUSINESS_ID(),
        params,
    );
}

async function getBusinessid(id: number) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(API_CONFIG.getBusinessid + get_BUSINESS_ID() + '/' + id);
}

async function updateOffers(params: any, id: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(API_CONFIG.updateOffers + get_BUSINESS_ID() + '/' + id, params);
}
async function delDocument(id: any, documentId: any) {
    const instance = getProtectedInstance(false);
    return await instance.post(API_CONFIG.delDocument + get_BUSINESS_ID() + '/' + "BC5622604346" + '/' + 'DC8742958993');
}

async function deleteLender(lenderInfoId: any) {
    const instance = getProtectedInstanceFor("lender");
    return await instance.post(`dsa/lender/delete/${lenderInfoId}`);
}

async function updateCase(caseId: any, caseStatus: any, payload: any, productId: any) {
    const instance = getProtectedInstanceFor("lender");
    return await instance.post(`dsa/lender/case/${caseId}/status/${caseStatus}/lender-details/update?loanProductId=${productId}`, { ...payload });
    // return await instance.post(`dsa/lender/case/${caseId}/status/${caseStatus}/lender-details/update`, { ...payload });
}

async function closeCase(caseId: any, payload: any) {
    const instance = getProtectedInstance();
    return await instance.post(`${API_CONFIG.updateCase}${caseId}/status`, { ...payload });
}

async function searchUser(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(`${API_CONFIG.searchUser}`, { params });
}

async function getUserProfileDetails() {
    const instance = getProtectedInstance();
    return await instance.get("get/profile-details");
}

async function getUser(id: number) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.user + get_BUSINESS_ID() + '/' + id);
}

async function statusChange(id: any, status: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.statusChange + id + '/' + status);
}

async function LenderstatusChange(id: any, status: any) {
    const instance = getProtectedInstanceFor("lender");
    return await instance.post(API_CONFIG.LenderstatusChange + id + '/' + status);
}


async function downloadDocument(businessId: any, documentId: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    const url = `document-upload/get/${documentId}`
    return await instance.get(url);
}

async function downloadAllDocument(documentSetId:any) {
    const instance = getProtectedInstanceForTherdServer(false);
    const url = `document-upload/documentset/${documentSetId}/download-zip`
    return await instance.get(url);
}

async function downloadAllDocumentLink(page:any, pageSize:any) 
{
    const instance = getProtectedInstanceForTherdServer(false);
    const url = `dsa/notification/list/?pageNo=${page}&pageSize=${pageSize}`
    return await instance.get(url);    
}

async function getDocDetails(id: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(`document-upload/get/${id}`);
}

async function deleteAppContent(id: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(
        API_CONFIG.deleteAppContent + '/' + get_BUSINESS_ID() + '/' + id,
    );
}

async function updateAppContent(params: any, id) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(
        API_CONFIG.updateAppContent + '/' + get_BUSINESS_ID() + '/' + id,
        params,
    );
}

async function termsAndConditions() {
    const instance = getProtectedInstance(false);
    return await instance.get(
        API_CONFIG.helpTermsAndConditions + 'BU6098926543/TERMS_AND_CONDITIONS'
    );
}

async function privacyPolicy() {
    const instance = getProtectedInstance(false);
    return await instance.get(
        API_CONFIG.helpPrivacyPolicy + 'BU6098926543/PRIVACY_POLICY'
    );
}

async function faq() {
    const instance = getProtectedInstance(false);
    return await instance.get(
        API_CONFIG.helpFaq + 'BU6098926543/FAQ'
    );
}

async function getTermsNCondition(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(
        API_CONFIG.listAppContent +
        '/BU6098926543/TERMS_AND_CONDITIONS',
        { params },
    );
}
async function getPrivacyPolicy(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(
        API_CONFIG.listAppContent + '/BU6098926543/PRIVACY_POLICY',
        { params },
    );
}

async function getUsersPermissionList(params) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.listUserPermissions, { params });
}

async function getUsersPermission(userId: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.userPermission + userId + '/permissions');
}

async function updatePermission(userId: any, payload) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.updatePermission + userId + '/permissions', payload);
}

async function updatePassword(payload) {
    const instance = getProtectedInstance(false);
    return await instance.post(API_CONFIG.updatePassword, payload);
}

async function resetPassword(payload) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.resetPassword, payload);
}

async function getAPIForDeleteDataInList(id: number) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(API_CONFIG.deleteOffersAndNotice + get_BUSINESS_ID() + '/' + id);
}

async function getLoanProductList(businessId: number) {
    const instance = getProtectedInstance(true);
    const url = `${API_CONFIG.loanProductsList}${businessId}/loan_product`
    return await instance.get(url);
}

async function getLoanProductListCommon() {
    const instance = getProtectedInstance(false);
    return await instance.get("list/loan_product");
}

async function getListReportees(managerId: number) {
    const instance = getProtectedInstance(false);
    const url = `${API_CONFIG.listReporters}${managerId}/list_reportees`
    return await instance.get(url);
}

async function getLenderAdditonalField(lenderId: string, caseId: string, productType: string) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/${lenderId}/product/fields?productType=${productType}`
    // const url = `dsa/lender/${lenderId}/fields?caseId=${caseId}&loanProductId=${productType}`
    //  const url = `dsa/lender/${lenderId}/${productType}/fields?caseId=${caseId}`
    const url = `dsa/lender/start-lender-processing/${lenderId}/case/${caseId}`
    // const url = `dsa/lender/${lenderId}/fields`
    return await instance.get(url);
}
async function submitTask(lenderId: string, caseId: string, isConsent: boolean, payload) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/start-lender-processing/${lenderId}/case/${caseId}`
    let url = `dsa/lender/submit-task/${lenderId}/case/${caseId}`
    if(isConsent)
        {
             url = `/dsa/lender/resend/consent-sms/${lenderId}/case/${caseId}`
        }
    return await instance.post(url, payload);
}

async function submitTaskFree(payload) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/free/submit-task`
    return await instance.post(url, payload);
}

async function getNextTask(caseId: any, lenderId: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/get-next-task/${lenderId}/case/${caseId}`
    return await instance.get(url);
}

async function getNextTaskFree(payload: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/free/get-next-task`
    return await instance.post(url, payload);
}

async function updateLenderAdditonalField(lenderId: string, caseId: string, productType: string, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const user = localStorage.getItem('user');
    let userId: any = "";
    if (user) {
        userId = JSON.parse(user as string).userDetails.userId;
    }
    const url = `dsa/lender/${lenderId}/${productType}/case/${caseId}/${userId}`
    return await instance.post(url, payload);
}

async function updateLenderAdditonalFieldForBajaj(lenderId: string, caseId: string, productType: string, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const user = localStorage.getItem('user');
    let userId: any = "";
    if (user) {
        userId = JSON.parse(user as string).userDetails.userId;
    }
    const url = `dsa/lender/submit-to-lender/${lenderId}/case/${caseId}/${userId}`
    // const url = `dsa/lender/${lenderId}/${productType}/case/${caseId}/${userId}`
    return await instance.post(url, payload);
}
async function updateLenderAdditonalFieldForBajajSaleUser(lenderId: string, caseId: string, productType: string, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const user = localStorage.getItem('user');
    let userId: any = "";
    if (user) {
        userId = JSON.parse(user as string).userDetails.userId;
    }
    const url = `dsa/lender/submit-to-lender-bajaj/${lenderId}/case/${caseId}/${userId}`
    // const url = `dsa/lender/${lenderId}/${productType}/case/${caseId}/${userId}`
    return await instance.post(url, payload);
}

async function getLenderDocumentlist(lenderId: string, caseId: string) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderId}/document/list?caseId=${caseId}`
    return await instance.get(url);
}

async function renameDocument(docId: string, payload: any) {
    const instance = getProtectedInstance(false);
    const url = `documents/${docId}/update`
    return await instance.post(url, payload);
}

async function getLenderDocumentUploadDetails(lenderId: string, caseId: string) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderId}/case/${caseId}/lender_document_upload_details`
    return await instance.get(url);
}

async function postLenderDocumentUploadDetails(lenderId: string, caseId: string) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderId}/case/${caseId}/lender_document_upload_details`
    return await instance.post(url);
}

async function assignSalesUserToLead(payload: any) {
    const instance = getProtectedInstance();
    const url = `update/sales-user`
    return await instance.post(url,payload);
}

async function assignSalesUserToConnector(salesUserId: number, payload: object) {
    const instance = getProtectedInstance();
    return await instance.post((API_CONFIG.assignSalesUserToConnector + salesUserId), payload);
}

async function generateLeadsReport(startDate: string, endDate: string, reportType) {
    const instance = getProtectedInstanceForBlob();
    const url = `${API_CONFIG.generateLeadsReport}startDate=${startDate}&endDate=${endDate}&reportType=${reportType}`
    return await instance.get(url);
}

async function generateAttendanceReport(startDate: string, endDate: string, reportType) {
    const instance = getProtectedInstanceForBlob();
    const url = `${API_CONFIG.generateAttendanceReport}startDate=${startDate}&endDate=${endDate}&reportType=${reportType}`
    return await instance.get(url);
}

async function generateReportByReportType(startDate: string, endDate: string, reportType) {
    const instance = getProtectedInstanceForBlob();
    const url = `${API_CONFIG.generateReportByReportType}startDate=${startDate}&endDate=${endDate}&reportType=${reportType}`
    return await instance.get(url);
}

async function getPayoutCaseDisbursed(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.payoutOfCaseDisbursed, { params })
}

async function getPayoutList(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.PayoutOfList, { params })
}

async function getPayoutsOfConnectors(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.payoutsOfConnectorList, { params });
}

async function updatePayoutStatus(params: any) {
    const instance = getProtectedInstance();
    return await instance.put(API_CONFIG.updatePayoutStatus, params)
}

async function downloadPayoutDetails(payoutStatus: string, searchVal?: string) {
    const instance = getProtectedInstanceForBlob();
    let url: any = API_CONFIG.downloadPayoutDetails + `?payoutStatus=${payoutStatus}`
    if ((searchVal?.length ?? 0) > 0) {
        url = url + `&searchParam=${searchVal}`
    }
    return await instance.get(url);
}

async function approveRetryPayouts(isApprove: boolean, payload: object) {
    const instance = getProtectedInstance();
    return await instance.post((API_CONFIG.approvePayoutInfo + (isApprove ? "approve" : "retry")), payload);
}

async function bulkUploadStart(entityType?: any, params?: any) {
    const instance = getProtectedInstance();
    let url: any = API_CONFIG.bulkUploadStart
    if ((entityType?.length ?? 0) > 0) {
        url = API_CONFIG.bulkUploadStart + `?entityType=${entityType}`
    }
    return await instance.post(url, params);
}

async function bulkUploadMap(uploadId: string, entityType: string) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.bulkUploadMap + uploadId + "/extract/headers?entityType=" + entityType + "");
}

async function bulkUploadExecute(uploadId: string, entityType: string) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.bulkUploadExecute + uploadId + "/execute?entityType=" + entityType + "");
}

async function getBulkOperationList(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get("bulk-operation", { params });
}

async function bulkUploadDelete(uploadId: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.bulkUploadDelete + uploadId + "/update/delete-status");
}

async function getBulkUploadList(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.bulkUploadList, { params });
}

async function getDownloadFormate(entityType: string) {
    const instance = getProtectedInstanceForDownload();
    return await instance.get(API_CONFIG.downloadFormate + "?entityType=" + entityType + "");
}

async function attendanceUser(payload: object) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.attendance, payload);
}

async function getAttendanceList(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.listAttendance, { params });
}

async function getAttendanceById(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.listAttendanceById, { params });
}

async function getPartnerIdentificationDetails(lenderId: any, loanProductCode: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/business/${get_BUSINESS_ID()}/lender/${lenderId}/partner_identification_details?loanProductId=${loanProductCode}`
    return await instance.get(url);
}

async function updatePartnerIdentificationDetails(lenderId: any, params: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/business/${get_BUSINESS_ID()}/lender/${lenderId}/update_partner_identification_details`
    return await instance.post(url, params);
}

async function updateDocumentToLender(lenderId: any, loanProductId: any, caseId: any, params: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderId}/${loanProductId}/case/${caseId}/submit_documents_to_lender`
    return await instance.post(url, params);
}

async function updateDocumentMapping(lenderId: any, loanProductId: any, caseId: any, params: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderId}/case/${caseId}/product/${loanProductId}/document/mapping/update`
    return await instance.post(url, params);
}

async function getUsersByDepartment(params?: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(`${API_CONFIG.usersByDepartment}`, { params });
}

async function getShareLinkId(caseId: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(`${API_CONFIG.getShareLinkId + caseId}`);
}
async function getCredilioURL(lenderInfoId: any, loanProductID: any, lenderID: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderInfoId}/product/${loanProductID}/case/${caseId}/${lenderID}/redirect_url`
    return await instance.get(url);
}
async function validateAdditonalField(lenderId: any, caseId: any, params: any) {
    const instance = getPubslicInstanceForTherdServer(true);
    const url = `dsa/lender/${lenderId}/case/${caseId}/validate/additional-field`
    return await instance.post(url, params);
}
async function lenderCaseReject(lenderId: any, caseId: any, params: any) {
    const instance = getPubslicInstanceForTherdServer(true);
    const url = `dsa/lender/${lenderId}/case/${caseId}/reject`
    return await instance.post(url, params);
}
async function moneyWideConsent(caseId: any) {
    const instance = getPubslicInstanceForTherdServer(true);

    const url = `/dsa/lender/moneywide/tnc/consent/${caseId}`
    return await instance.post(url, {});
}
async function moneyWideCheckConsent(caseId: any) {
    const instance = getPubslicInstanceForTherdServer(true);

    const url = `/dsa/lender/moneywide/consent/${caseId}`
    return await instance.get(url);
}
async function moneyWideTriggerConsentSMS(caseId: any) {
    const instance = getProtectedInstanceForTherdServer(true);

    const url = `dsa/lender/moneywide/tnc/${caseId}/retrigger-sms`;
    return await instance.post(url, {});
}
async function sendCashESMSLink(lenderID: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/${lenderInfoId}/product/${loanProductID}/case/${caseId}/${lenderID}/redirect_url`
    const url = `dsa/lender/cashe/${caseId}/lender/${lenderID}/retrigger-sms`
    // api/dsa/lender/cashe/DC1025619905/lender/BU1400904544/retrigger-sms
    return await instance.get(url);
}
async function deleteDocReference(caseId: any, documentBusinessID: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/all/case/${caseId}/remove_document_references/${documentBusinessID}`
    return await instance.get(url);
}
async function updateBankDetailForLendingKart(documentBusinessID: any, payload: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    const url = `documents/${documentBusinessID}/update`
    return await instance.post(url, payload);
}
async function updateDisbursedStatus(caseID: any, lenderID: any, payload: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderID}/case/${caseID}/status_push`
    return await instance.post(url, payload);
}
async function getOtpVerificationDetails(lenderId: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/get-next-task/${lenderId}/case/${caseId}`
    const url = `dsa/lender/icici/get/otp-verification/applicant?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.get(url);
}
async function updateVerifyOtpICICI(lenderId: any, caseId: any, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/icici/verify/otp?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.post(url, payload);
}
async function updateRetrigerVerifyOtpICICI(lenderId: any, caseId: any, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/icici/re-trigger/otp?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.post(url, payload);
}

async function updateRetriveOfferAcceptance(lenderId: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/icici/get/latest/loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`;
    // const url = `dsa/lender/icici/re-trigger/otp?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.get(url);
}
async function getUpdatedICICIEmiCal(lenderId: any, caseId: any, payload) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/icici/get/latest/loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`;
    const url = `dsa/lender/icici/fetch-loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.post(url, payload);
}
async function getSanctionLetterForICICI(lenderId: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/icici/get/latest/loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`;
    const url = `dsa/lender/icici/download/letter?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.get(url);
}
async function getDocumentURLForABCL(lenderId: any, caseId: any, arg) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/icici/get/latest/loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`;
    // const url = `dsa/lender/icici/download/letter?caseId=${caseId}&lenderId=${lenderId}`
    const url = `/dsa/lender/${lenderId}/case/${caseId}/get/url?by=${arg}`;
    return await instance.get(url,arg);
}
async function getOffersForABCL(lenderId: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/icici/get/latest/loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`;
    // const url = `dsa/lender/icici/download/letter?caseId=${caseId}&lenderId=${lenderId}`
    const url = `/dsa/lender/${lenderId}/case/${caseId}/get/offers`;
    return await instance.get(url);
}

async function getMultipleLenderOffer(caseId: any, page: any, pageSize: any) {
    const instance = getProtectedInstance();
    // const url = `dsa/lender/icici/get/latest/loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`;
    // const url = `dsa/lender/icici/download/letter?caseId=${caseId}&lenderId=${lenderId}`
    const url = `list/multi-lender-offer/${caseId}?pageNo=${page}&pageSize=${pageSize}`;
    return await instance.get(url);
}

async function checkValidCaseID(caseID: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `/dsa/lender/free/check-case/${caseID}`;
    return await instance.get(url);
}

async function addCreateUserDetails(payload?: any) {
    const instance = getProtectedInstance(true);
    const url = `/v2/add/case`;
    return await instance.post(url,payload);
}

async function updateLoanProductandStartFlowable(params: any) {
  const instance = getProtectedInstance(true);
  let url = `check-version/update-product?caseId=${params?.caseId}&loanProductId=${params?.loanProductId}`;
  if(params?.insuranceCaseOnline != undefined) {
    url = url + `&insuranceCaseOnline=${params?.insuranceCaseOnline ?? null}`;
  }
  return await instance.post(url);
}

async function fetchProductWorkflowById(id:string) {
    const instance = getProtectedInstance(false);
    const url = `v1/product-workflow/master-product/${id}`;
    return await instance.get(url);
}

async function listDecisioning(caseId:string,checkAvailability:boolean, decisioningMasterId?:string, journeyId?:string) {
    const instance = getProtectedInstance(false);
    let url = `decision/data/list?caseId=${caseId}&checkAvailability=${checkAvailability}`;
    if((decisioningMasterId?.length ?? 0) > 0){
        url = url + `&decisioningMasterId=${decisioningMasterId }`;
    }
    if((journeyId?.length ?? 0) > 0){
        url = url + `&journeyIds=${journeyId}`;
    }
    return await instance.get(url);
}

async function listDocumentV2(caseId:string) {
    const instance = getProtectedInstance();
    const url = `list/document/case/${caseId}`;
    return await instance.get(url);
}

async function updateCaseStatus(caseId:string, payload) {
    const instance = getProtectedInstance();
    const url = `update/case/${caseId}/status`;
    return await instance.post(url, payload);
}

async function productGetNextTask(payload: any) {
    const instance = getProtectedInstance();
    const url = 'product-workflow/get-task';
    return await instance.post(url, payload);
}

async function productSubmitTask(payload: any) {
    const instance = getProtectedInstance(true);
    const url = 'product-workflow/submit-task';
    return await instance.post(url, payload);
}

async function purgeAllCaseDecisioningData(caseId:string) {
    const instance = getProtectedInstance(false);
    const url = `decision/data/purge/${caseId}`;
    return await instance.put(url);
}

async function getFinboxURL(userId: String) {
    const date = dayjs().subtract(1, "year").startOf("month");
    const toDate = dayjs().subtract(1, "month").endOf("month");
    const params = {
      link_id: userId,
      api_key: "sC5eBP7oZe8x0wF7fbqC0bY0EAQOjPRIWhztZIli",
      from_date: date.format("DD/MM/YYYY"),
      to_date: toDate.format("DD/MM/YYYY"),
      logo_url:
        "https://oneflo.in/static/media/dashboard-logo.4ffa136039642bb9b45b.jpeg",
    };
    // to_date
    const instance = getProtectedInstanceForFinbox();
    return await instance.post(
      "https://portal.finbox.in/bank-connect/v1/session/",
      params
    );
  };

  async function getAnalysis(entityId: string, caseId: string) {
    const instance = getProtectedInstance(false);
    return await instance.get(
      `bank/statement/create-document/get-analysis/entity/${entityId}/case/${caseId}`
    );
  }

  async function getAnalysisCheck(caseId:string) {
    const instance = getProtectedInstance(false);
    return await instance.put(
        `bank/statement/test-data/get-analysis/case/${caseId}`
    )
  }

  async function getShowFinboxStatus() {
    const instance = getProtectedInstance(false);
    return await instance.get('bank/show-widget/status');
  }

  async function finBoxEventAuditCase(payload: any) {
    const instance = getProtectedInstance();
    const url = 'finbox-event/audit/case';
    return await instance.post(url, payload);
  }

  async function getVASActionStatus(caseId:string) {
    const instance =getProtectedInstance();
    return await instance.get(`case/${caseId}/action/status`);
  }

  async function getLenderRequestPayload(caseId: string, lenderId: string) {
    const instance = getProtectedInstance();
    return await instance.get(`case/${caseId}/lender/${lenderId}/request-payload`);
  }
  async function sendWhatsAppMessage(caseID:any){
    const instance = getProtectedInstance();
    const url = `/communication/whatsapp/document_collection/case/${caseID}`;
    return await instance.post(url); 
}

async function getWhatsAppDocumentValidate(caseID:any) {
    const instance = getProtectedInstance();
    return await instance.get(`communication/whatsapp/document_collection/case/${caseID}/validate`);
  }

async function updateCaseCWOfferDetails(caseId:string, journeyId:string) {
const instance = getProtectedInstance();
return await instance.put(`case/publish-cw-offer?caseId=${caseId}&journeyId=${journeyId}`);
}
    async function updateDocumentBankDetail(documentBusinessID:string, payload: any) {
        const instance = getProtectedInstance(false);
        const url = `document-upload/${documentBusinessID}/update/v2`;
        return await instance.put(url, payload);
    }

    async function getDocumentBankList(masterKey:string) {
        const instance = getProtectedInstance();
        const url = `dc-master-data/list/${masterKey}`;
        return await instance.get(url);
    }

    // getProtectedInstanceForTherdServer
    async function getDocumentBankListFoLender(masterKey:string, lenderShortCode: string) {
        const instance = getProtectedInstanceForTherdServer();
        const url = `dsa/lender/${lenderShortCode}/product/ALL/master/${masterKey}/getLenderMasterData?pageSize=10000&limit=10000&skip=0`
        return await instance.get(url);
    }

    async function getLenderShortCode(lenderInfoID: string) {
        const instance = getProtectedInstanceForTherdServer();
        const url = `dsa/lender/get-lender-short-code/${lenderInfoID}`
        return await instance.get(url);
    }

    async function updateConsentForPrefrLender(consentID: any, payload: any) {
        const instance = getProtectedInstanceForTherdServer();
        const url = `/dsa/lender/prefr/free/view-offer?consentId=${consentID}`;
        return await instance.post(url, payload);
    }

    async function getScorecardPublisList(id: any) {
        const instance = getProtectedInstanceForTherdServer(false);
        return await instance.get(`decision/published/${id}`)
    }

    async function listAllTheFinanciers(params?:any) {
        const instance = getProtectedInstanceForTherdServer(false);
        return await instance.get('business/list/financier', {params})
    }

    async function getPublishDetails(params: any) {
        const instance = getProtectedInstanceForTherdServer(false);
        return await instance.get(`decision/published/${params.decisioningPublishedId}`);
    }

    async function getDecentroFinbox(params: any) {
        const instance = getProtectedInstanceForTherdServer(false);
        return await instance.get(`decision/audit/vas`, { params });
    }

    async function getDecisioningAuditList(params: any) {
        const instance = getProtectedInstanceForTherdServer(false);
        return await instance.get(`decision/audit`, { params });
    }

const API_SERVICE = {
    get_BUSINESS_ID,
    getIPData,
    LenderstatusChange,
    updateEmployee,
    getPrivacyPolicy,
    getTermsNCondition,
    termsAndConditions,
    privacyPolicy,
    getTenantDocumentDetails,
    faq,
    updateAppContent,
    deleteAppContent,
    statusChange,
    getUser,
    delDocument,
    getBusinessid,
    updateOffers,
    login,
    checkPhone,
    checkRecaptchaResponse,
    verifyOtp,
    getLeads,
    getLeadsByFilter,
    getHeirarchyUsers,
    getLeadsByLender,
    getTypes,
    updateConnectorBusinessId,
    getBusinessIdUpdate,
    getConnector,
    getConnectors,
    getConnectorById,
    getSubConnectors,
    checkPanDetails,
    checkPanDetailsForProfile,
    panVerify,
    getPanVerify,
    checkPincode,
    checkPincodeForBajaj,
    addLead,
    getLeadAdditional,
    editLead,
    addConnector,
    updateConnector,
    updateConnectorStatus,
    getLeadsById,
    getBreStatus,
    fileUpload,
    getDocumentListFree,
    getDocumentList,
    getReplaceDocumentList,
    deleteDocument,
    getLenders,
    getLendersByType,
    getBasicOfferByCaseId,
    getPaySubConnector,
    updateBankInfoFlagForConnectors,
    addLender,
    submitTask,
    submitTaskFree,
    getNextTask,
    getNextTaskFree,
    updateLender,
    addInsurance,
    getInsurance,
    updateInsuranceStatus,
    updateProductPlan,
    getLenderListInsurer,
    getFiList,
    getProductPlanList,
    getEmployees,
    deleteLender,
    updateCase,
    searchUser,
    closeCase,
    addDepartment,
    getDepartments,
    addDesignations,
    getDesignations,
    addLocations,
    getLocations,
    getOffers,
    addOfferOrNotice,
    addGreetings,
    getNotices,
    getManager,
    getUsersWithRole,
    addEmployee,
    getBureauCredits,
    getAppContent,
    getAppContentFAQ,
    addAppContent,
    addLoanProduct,
    getLoanProduct,
    handleErrors,
    downloadDocument,
    downloadAllDocument,
    downloadAllDocumentLink,
    getDocDetails,
    getUsersPermissionList,
    getUsersPermission,
    updatePermission,
    getUserProfileDetails,
    updatePassword,
    resetPassword,
    getAPIForDeleteDataInList,
    getLoanProductList,
    getLoanProductListCommon,
    getListReportees,
    getLenderAdditonalField,
    updateLenderAdditonalField,
    updateLenderAdditonalFieldForBajaj,
    updateLenderAdditonalFieldForBajajSaleUser,
    getLenderDocumentlist,
    renameDocument,
    getLenderDocumentUploadDetails,
    postLenderDocumentUploadDetails,
    assignSalesUserToConnector,
    assignSalesUserToLead,
    searchCustomers,
    generateLeadsReport,
    getPayoutCaseDisbursed,
    getPayoutList,
    getPayoutsOfConnectors,
    updatePayoutStatus,
    downloadPayoutDetails,
    approveRetryPayouts,
    addDocument,
    uploadDocument,
    getDocumentHierarchyList,
    bulkUploadStart,
    bulkUploadMap,
    bulkUploadExecute,
    getBulkOperationList,
    bulkUploadDelete,
    getBulkUploadList,
    getGreetings,
    getAllNotificationList,
    getNotificationList,
    updateNotificationStatus,
    getUnreadNotificationCnt,
    clearAllNotifications,
    getDownloadFormate,
    attendanceUser,
    getAttendanceList,
    getAttendanceById,
    getLeadsByFilterPostCase,
    getBureauScore,
    getConsentInfo,
    checkExpireyLink,
    updateConsent,
    genrateBureauScore,
    getPartnerIdentificationDetails,
    updatePartnerIdentificationDetails,
    updateDocumentToLender,
    updateDocumentMapping,
    getUsersByDepartment,
    generateAttendanceReport,
    generateReportByReportType,
    getLeadList,
    getCaseDetailsByShareLinkId,
    getShareLinkId,
    getCredilioURL,
    validateAdditonalField,
    lenderCaseReject,
    moneyWideConsent,
    moneyWideCheckConsent,
    moneyWideTriggerConsentSMS,
    sendCashESMSLink,
    deleteDocReference,
    updateBankDetailForLendingKart,
    updateDisbursedStatus,
    getOtpVerificationDetails,
    updateRetrigerVerifyOtpICICI,
    updateVerifyOtpICICI,
    updateRetriveOfferAcceptance,
    getUpdatedICICIEmiCal,
    getSanctionLetterForICICI,
    getDocumentURLForABCL,
    getOffersForABCL,
    getMultipleLenderOffer,
    checkValidCaseID,
    addCreateUserDetails,
    updateLoanProductandStartFlowable,
    fetchProductWorkflowById,
    listDecisioning,
    listDocumentV2,
    updateCaseStatus,
    productGetNextTask,
    productSubmitTask,
    purgeAllCaseDecisioningData,
    getFinboxURL,
    getAnalysis,
    getAnalysisCheck,
    getShowFinboxStatus,
    finBoxEventAuditCase,
    getVASActionStatus,
    getLenderRequestPayload,
    sendWhatsAppMessage,
    getWhatsAppDocumentValidate,
    updateCaseCWOfferDetails,
    updateDocumentBankDetail,
    getDocumentBankList,
    getDocumentBankListFoLender,
    getLenderShortCode,
    updateConsentForPrefrLender,
    getScorecardPublisList,
    listAllTheFinanciers,
    getDecisioningAuditList,
    getDecentroFinbox,
    getPublishDetails,
};
export default API_SERVICE;