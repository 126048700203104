import "./index.scss";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import {
  Input,
  Space,
  Button,
  Row,
  Col,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Form,
  notification,
  AutoComplete,
  Select,
  Modal,
  Dropdown,
  Menu,
  List,
  Card,
} from "antd";
import { FaSearch } from "react-icons/fa";
import API_SERVICE from "shared/services/api-service";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import debounce from "lodash/debounce";
import { getUser, hasUserRole } from "shared/services/Utility";
import type { ColumnsType } from "antd/es/table";
import { ReactComponent as IconShare } from "../../../assets/images/shareIcon.svg";
import { ReactComponent as IconPlayNewBorder } from "../../../assets/images/play_new_border.svg";
import { ReactComponent as IconEditBorder } from "../../../assets/images/edit_border.svg";
import whatsappIcon from "../../../assets/images/whatsappIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import {
  setActiveKey,
  setIsShowFilter,
  setLeadPageNo,
  setLeadPageSize,
  setLeadSearchParam,
  setPostLenderCase,
  setPreLenderCase,
  setRestFilterData,
} from "shared/redux/lead-reducer";
import LeadFilter from "./LeadFilter/LeadFilter";
import blackFilterIcon from "../../../assets/images/blackFilter.svg";
import blackRefereshIcon from "../../../assets/images/blackReferesh.svg";
import { setScreenHeader } from "shared/redux/common-reducer";
import { DownOutlined } from "@ant-design/icons";

const { CheckableTag } = Tag;
const { TabPane } = Tabs;

type Props = {};

const LeadPage: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const location = useLocation() as any;
  const [allLeadsData, setAllLeadsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [counts, setCounts] = useState({
    totalCases: 0,
    totalCasesInLeadState: 0,
    totalCasesInProspectState: 0,
    totalCasesInVisitState: 0,
    totalCasesInDocumentState: 0,
    totalLenderAssignedCases: 0,
    totalLenderLoggedInCases: 0,
    totalLenderSanctionedCases: 0,
    totalLenderRejectedCases: 0,
    totalLenderWithdrawnCases: 0,
    totalLenderPendencyCases: 0,
    totalLenderDisbursedCases: 0,
    totalInsurerPolicyIssued: 0,
    totalInsurerPolicyCounterOffer:0,
    totalInsurerPolicyUnderwritingReject:0,
  });
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [prospectOnboardingStatus, setProspectOnboardingStatus] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const lead: any = useSelector((state: RootState) => state.lead);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [assignToData, setAssignToData] = useState([] as any);
  const [selectedAssignTo, setSelectedAssignTo] = useState(null as any);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorCaseIds, setErrorCaseIds] = useState([] as any);
  const [selectedCaseIds, setSelectedCaseIds] = useState([] as any);
  const [isModalWhatsappVisible, setIsModalWhatsappVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>(null);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isWhatsappEnable, setIsWhatsappEnable] = useState(false);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  const { appColors, isMobile }: any = useSelector(
    (state: RootState) => state.common
  );

  const preLenderColumns: ColumnsType<any> = [
    {
      title: "Lead",
      dataIndex: ["contactDetails", "firstName"],
      key: "name",
      width: 50,
      ellipsis: true,
    },
    {
      title: "Product Type",
      dataIndex: ["loanType"],
      key: "productType",
      ellipsis: true,
    },
    {
      title: "Lender Name",
      dataIndex: ["lenderProposalDTO", "allLenderNames"],
      key: "allLenderNames",
      ellipsis: true,
      render: (_: any, record: any) => {
        const lenderNames = record?.lenderProposalDTO?.allLenderNames;
        if (lead?.activeKey == "PRE_LENDER" && (lenderNames?.length ?? 0) > 0) {
          if ((lenderNames?.length ?? 0) > 0) {
            return (
              <div style={{ width: "200px", whiteSpace: "pre-wrap" }}>
                <text>{lenderNames}</text>
              </div>
            );
          }
        }
        return "NA";
      },
    },
    {
      title: "Lender Current Stage",
      dataIndex: ["lenderProposalDTO", "allLenderStages"],
      key: "allLenderStages",
      ellipsis: true,
      render: (_: any, record: any) => {
        let lenderStage = record?.lenderProposalDTO?.allLenderStages;
        return (
          <div style={{ width: "200px", whiteSpace: "pre-wrap" }}>
            <text>{lenderStage ?? ""}</text>
          </div>
        );
      },
    },
    {
      title: "Drop Off Stage",
      dataIndex: "currDropOffStage",
      key: "currDropOffStage",
      width: 50,
      ellipsis: true,
      render: (_: any, record: any) => {
        let value = {} as any;
        value = prospectOnboardingStatus?.find((item: any) => {
          return item?.key === record?.currDropOffStage;
        });
        return value?.value;
      },
    },
    {
      title: "Version",
      width: 50,
      dataIndex: "productVersion",
      key: "version",
      align: "center",
    },
    {
      title: "Case Stage",
      dataIndex: "caseStatus",
      key: "stage",
    },
    {
      title: "Lead Originator",
      dataIndex: ["leadOriginatorDetails", "companyName"],
      key: "leadOriginator",
    },
    {
      title: "Mobile Number",
      dataIndex: ["contactDetails", "mobileNumber"],
      key: "mobileNumber",
      render: (_: any, record: any) =>
        record?.contactDetails?.mobileNumber.startsWith("1")
          ? ""
          : record?.contactDetails?.mobileNumber,
    },
    {
      title: "Case Created Date",
      dataIndex: "caseCreatedDate",
      key: "caseCreatedDate",
      width: 180,
      render: (_: any, record: any) =>
        moment(record?.caseCreatedDate)
          .locale("en")
          .format("MMM, DD, YYYY HH:mm"),
    },
    {
      title: "Days Open",
      dataIndex: "daysOpen",
      key: "daysOpen",
      width: 30,
    },
    {
      title: "Assign to Sales User",
      dataIndex: "assignedToSalesUserName",
      key: "assignedSalesUserName",
      width: 30,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      align: "center",
      width: 60,
      render: (_: any, record: any) => {
        const isAction =
          ("LEAD" === lead?.preLenderCase && "PRE_LENDER" === lead?.activeKey) ||
          ("PRE_LENDER" === lead?.activeKey &&
            "ALL" === lead?.preLenderCase &&
            record?.caseStatus === "Lead");
        let isLoanProduct = (record?.loanProductId?.length ?? 0) == 0;
        return (
          <Space className="action-button" size="small">
            <Button
              className="custome-btn-icon-secondary"
              style={{ "--bgColor": appColors?.appSecondaryColor ?? "" } as any}
              size="small"
              // type="primary"
              // shape="circle"
              hidden={
                "LEAD" === lead.preLenderCase && "PRE_LENDER" === lead.activeKey
              }
              onClick={() => getShareLinkId(record)}
              icon={<IconShare />}
            />
            {isWhatsappEnable && (
              <Button
                size="small"
                hidden={!record?.isWhatsAppEnabled}
                onClick={() => {
                  setSelectedLead(record);
                  handleClick(record?.caseId);
                }}
                icon={
                  <img
                    src={whatsappIcon}
                    alt=""
                    height={24}
                    style={{ marginTop: "-4px" }}
                  />
                }
              />
            )}

            {isAction || isLoanProduct ? (
              <div style={{ width: "24px" }}></div>
            ) : (
              <Button
                className="custome-btn-icon-play"
                style={{ "--bgColor": appColors?.appPrimaryColor ?? "" } as any}
                size="small"
                type="text"
                onClick={() => goToLeadDetails(record)}
                icon={<IconPlayNewBorder />}
              />
            )}
            <Button
              className="custome-btn-icon-secondary"
              style={{ "--bgColor": appColors?.appSecondaryColor ?? "" } as any}
              size="small"
              type="text"
              disabled={userRolePermission["ReadOnly"]}
              hidden={
                !(
                  userRolePermission["UpdateLead"] ||
                  userRolePermission["Admin"]
                )
              }
              onClick={() => goToLeadEdit(record)}
              icon={<IconEditBorder />}
            />
            {/* <Button
            className="pink"
            size="small"
            type="primary"
            shape="circle"
            icon={<AiFillDelete />}
          /> */}
          </Space>
        );
      },
    },
  ]

  const preLenderCases =
    tenantInfo?.businessProductType === "INSURANCE"
      ? [
          {
            key: "ALL",
            value: "All (" + counts.totalCases + ")",
          },
          {
            key: "LEAD",
            value: "Lead (" + counts.totalCasesInLeadState + ")",
          },
          {
            key: "PROSPECT",
            value: "Prospect (" + counts.totalCasesInProspectState + ")",
          },
          {
            key: "DOCUMENTATION",
            value: "Documentation (" + counts.totalCasesInDocumentState + ")",
          },
        ]
      : [
          {
            key: "ALL",
            value: "All (" + counts.totalCases + ")",
          },
          {
            key: "LEAD",
            value: "Lead (" + counts.totalCasesInLeadState + ")",
          },
          {
            key: "PROSPECT",
            value: "Prospect (" + counts.totalCasesInProspectState + ")",
          },
          {
            key: "VISIT",
            value: "Visit (" + counts.totalCasesInVisitState + ")",
          },
          {
            key: "DOCUMENTATION",
            value: "Documentation (" + counts.totalCasesInDocumentState + ")",
          },
        ];

  const postLenderCases =
    tenantInfo?.businessProductType === "INSURANCE"
      ? [
          {
            key: "LOGGED",
            value: "Log in (" + counts.totalLenderLoggedInCases + ")",
          },
          {
            key: "POLICY_ISSUED",
            value: "Policy Issued (" + counts.totalInsurerPolicyIssued + ")",
          },
          {
            key: "WITHDRAWN",
            value: "Withdrawn (" + counts.totalLenderWithdrawnCases + ")",
          },
          {
            key:"COUNTER_OFFER",
            value: "Counter Offer (" + counts.totalInsurerPolicyCounterOffer + ")",
          },
          {
            key:"UNDERWRITING_REJECT",
            value:"Underwriting Reject(" +counts.totalInsurerPolicyUnderwritingReject + ")",
          },
        ]
      : [
          {
            value: "Lender Selection (" + counts.totalLenderAssignedCases + ")",
            key: "ASSIGNED",
          },
          {
            value: "Log in (" + counts.totalLenderLoggedInCases + ")",
            key: "LOGGED",
          },
          {
            key: "SANCTIONED",
            value: "Sanctioned (" + counts.totalLenderSanctionedCases + ")",
          },
          {
            key: "REJECTED",
            value: "Rejected (" + counts.totalLenderRejectedCases + ")",
          },
          {
            key: "WITHDRAWN",
            value: "Withdrawn (" + counts.totalLenderWithdrawnCases + ")",
          },
          {
            key: "PENDENCY",
            value: "Pendency (" + counts.totalLenderPendencyCases + ")",
          },
          {
            key: "DISBURSED",
            value: "Disbursed (" + counts.totalLenderDisbursedCases + ")",
          },
        ];

  if (location?.state) {
    /*if (location?.state?.activeTab) {
      dispatch(setActiveKey(location?.state?.activeTab));
    }

    if (location?.state?.name && "POST_LENDER" === location?.state?.activeTab)
      dispatch(setPostLenderCase(location?.state?.name));
    else if (location?.state?.name)
      dispatch(setPreLenderCase(location?.state?.name));*/

    location.state = null;
  }

  /* useEffect functions */
  useEffect(() => {
    dispatch(setScreenHeader({ screenTitle: "Manage Leads" }));

    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredProspectOnboardingStatus = enumValues.find(
        (item: any) => item.propertyName === "PROSPECT_ONBOARDING_STATUS"
      );

      if (filteredProspectOnboardingStatus)
        setProspectOnboardingStatus(
          filteredProspectOnboardingStatus?.keyAndDisplayValueList
        );
    }
  }, []);

  useEffect(() => {
    if (
      userRolePermission["CreatAdmineOperations"] === true ||
      userRolePermission["CreateOperations"] === true ||
      userRolePermission["CreateLead"] === true
    ) {
      setAddButtonDisabled(false);
    } else {
      setAddButtonDisabled(true);
    }
    // dispatch(setLeadPageNo(1));
    form.setFieldsValue({
      searchBox: lead.searchParam,
    });

    getAllLeadsData(
      lead.pageNo,
      lead.pageSize,
      lead.searchParam,
      lead.filterData
    );
  }, [
    lead.activeKey,
    lead.searchParam,
    lead.preLenderCase,
    lead.postLenderCase,
    lead.filterData,
  ]);

  const colLenderName = (_: any, record: any) => {
    const lenderNames = lead.activeKey === "PRE_LENDER" ?
              record?.lenderProposalDTO?.allLenderNames : 
              record?.caseDetails?.allLenderNames;
    if (lead.activeKey == "PRE_LENDER" && (lenderNames?.length ?? 0) > 0) {
      if ((lenderNames?.length ?? 0) > 0) {
        return (
          <div style={{ width: "200px", whiteSpace: "pre-wrap" }}>
            <text>{lenderNames}</text>
          </div>
        );
      }
    } else {
      if (
        record?.caseDetails?.lenderActivityHolder?.lenderActivityDetails.length > 0
      ) {
        return (
          <div style={{ width: "200px", whiteSpace: "pre-wrap" }}>
            <text>
              {
                record?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.at(0)
                  ?.lenderName
              }
            </text>
          </div>
        );
      }
    }
    return "NA";
  };

  const CurrentStage = (_: any, record: any) => {
    let lenderStage = lead.activeKey === "PRE_LENDER" ?
                      record?.lenderProposalDTO?.allLenderStages :
                      record?.caseDetails?.allLenderStages;
    const lenderActivityDetails =
      record?.caseDetails?.lenderActivityHolder?.lenderActivityDetails;
    if (
      (lenderStage?.length ?? 0) === 0 &&
      (lenderActivityDetails?.length ?? 0) > 0
    ) {
      lenderStage =
        record.caseDetails.lenderActivityHolder.lenderActivityDetails[0].status;
    }
    return (
      <div style={{ width: "200px", whiteSpace: "pre-wrap" }}>
        <text>{lenderStage}</text>
      </div>
    );
  };

  const colDropOffStage = (_: any, record: any) => {
    let value = {} as any;
    value = prospectOnboardingStatus?.find((item: any) => {
      return item?.key === record?.currDropOffStage;
    });
    return value?.value;
  };

  const colMobileNumber = (_: any, record: any) =>
    record?.contactDetails?.mobileNumber.startsWith("1")
      ? ""
      : record?.contactDetails?.mobileNumber;

  const colCaseCreatedDate = (_: any, record: any) =>
    moment(record.caseCreatedDate).locale("en").format("MMM, DD, YYYY HH:mm");

  const colAction = (_: any, record: any) => {
    const isAction =
      ("LEAD" === lead?.preLenderCase && "PRE_LENDER" === lead?.activeKey) ||
      ("PRE_LENDER" === lead?.activeKey &&
        "ALL" === lead?.preLenderCase &&
        record?.caseDetails?.caseStatus === "Lead");
    let isLoanProduct = (record?.loanProductId?.length ?? 0) == 0;
    return (
      <Space className="action-button" size="small">
        {tenantInfo?.businessProductType !== "INSURANCE" &&
          <Button
            className="custome-btn-icon-secondary"
            style={{ "--bgColor": appColors?.appSecondaryColor ?? "" } as any}
            size="small"
            hidden={
              "LEAD" === lead?.preLenderCase && "PRE_LENDER" === lead?.activeKey
            }
            onClick={() => getShareLinkId(record)}
            icon={<IconShare />}
          />
        }
        {isWhatsappEnable && (
          <Button
            size="small"
            hidden={!record?.isWhatsAppEnabled}
            onClick={() => {
              setSelectedLead(record);
              handleClick(record?.caseDetails?.caseId);
            }}
            icon={
              <img
                src={whatsappIcon}
                alt=""
                height={24}
                style={{ marginTop: "-4px" }}
              />
            }
          />
        )}

        {isAction || isLoanProduct ? (
          <div style={{ width: "24px" }}></div>
        ) : (
          <Button
            className="custome-btn-icon-play"
            style={{ "--bgColor": appColors?.appPrimaryColor ?? "" } as any}
            size="small"
            type="text"
            onClick={() => goToLeadDetails(record)}
            icon={<IconPlayNewBorder />}
          />
        )}
        <Button
          className="custome-btn-icon-secondary"
          style={{ "--bgColor": appColors?.appSecondaryColor ?? "" } as any}
          size="small"
          type="text"
          disabled={userRolePermission["ReadOnly"]}
          hidden={
            !(userRolePermission["UpdateLead"] || userRolePermission["Admin"])
          }
          onClick={() => goToLeadEdit(record)}
          icon={<IconEditBorder />}
        />
      </Space>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "Lead",
      dataIndex: ["contactDetails", "firstName"],
      key: "name",
      width: 50,
      ellipsis: true,
    },
    {
      title: "Product Type",
      dataIndex: ["loanType"],
      key: "productType",
      ellipsis: true,
    },
    {
      title: "Lender Name",
      dataIndex: ["caseDetails", "allLenderNames"],
      key: "allLenderNames",
      ellipsis: true,
      render: colLenderName,
    },
    {
      title: "Lender Current Stage",
      dataIndex: ["caseDetails", "allLenderStages"],
      key: "allLenderStages",
      ellipsis: true,
      render: CurrentStage,
    },
    {
      title: "Drop Off Stage",
      dataIndex: "currDropOffStage",
      key: "currDropOffStage",
      width: 50,
      ellipsis: true,
      render: colDropOffStage,
    },
    {
      title: "Version",
      width: 50,
      dataIndex: "productVersion",
      key: "version",
      align: "center",
    },
    {
      title: "Case Stage",
      dataIndex: ["caseDetails", "caseStatus"],
      key: "stage",
    },
    {
      title: "Lead Originator",
      dataIndex: ["caseDetails", "connectorDetails", "companyName"],
      key: "sourceOfCase",
    },
    {
      title: "Mobile Number",
      dataIndex: ["contactDetails", "mobileNumber"],
      key: "mobileNumber",
      render: colMobileNumber,
    },
    {
      title: "Case Created Date",
      dataIndex: "caseCreatedDate",
      key: "caseCreatedDate",
      width: 180,
      render: colCaseCreatedDate,
    },
    {
      title: "Days Open",
      dataIndex: "daysOpen",
      key: "daysOpen",
      width: 30,
    },
    {
      title: "Assign to Sales User",
      dataIndex: ["caseDetails", "assignedToUserDetails", "firstName"],
      key: "assignedSalesUserName",
      width: 30,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      align: "center",
      width: 60,
      render: colAction,
    },
  ];

  const columnsInsurence: ColumnsType<any> = [
    {
      title: "Lead",
      dataIndex: ["contactDetails", "firstName"],
      key: "name",
      width: 50,
      ellipsis: true,
    },
    {
      title: "Product Type",
      dataIndex: ["loanType"],
      key: "productType",
      ellipsis: true,
    },
    {
      title: "Insurer",
      dataIndex: [lead.activeKey === "PRE_LENDER"?"lenderProposalDTO":"caseDetails", "allLenderNames"],
      key: "allLenderNames",
      ellipsis: true,
      render: colLenderName,
    },
    {
      title: "Insurer Current Stage",
      dataIndex: ["caseDetails", "allLenderStages"],
      key: "allLenderStages",
      ellipsis: true,
      render: CurrentStage,
    },
    {
      title: "Product Plan",
      dataIndex: [lead.activeKey === "PRE_LENDER"?"lenderProposalDTO":"caseDetails", "allInsuranceProductPlan"],
      key: "allInsuranceProductPlan",
      ellipsis: true,
    },
    {
      title: "Version",
      width: 50,
      dataIndex: "productVersion",
      key: "version",
      align: "center",
    },
    {
      title: "Case Stage",
      dataIndex: lead.activeKey === "PRE_LENDER" ? "caseStatus": ["caseDetails", "caseStatus"],
      key: "stage",
    },
    {
      title: "Journey Stage",
      dataIndex: "currDropOffStage",
      key: "currDropOffStage",
      width: 50,
      ellipsis: true,
      render: colDropOffStage,
    },
    {
      title: "Frequency of Payment",
      dataIndex: [lead.activeKey === "PRE_LENDER"?"lenderProposalDTO":"caseDetails", "allInsuranceFrequencyOfPayment"],
      key: "allInsuranceFrequencyOfPayment",
    },
    {
      title: "Premium (INR)",
      dataIndex: [lead.activeKey === "PRE_LENDER"?"lenderProposalDTO":"caseDetails", "allInsurancePremium"],
      key: "allInsurancePremium",
    },
    {
      title: "Lead Source",
      dataIndex: lead.activeKey === "PRE_LENDER" ?["leadOriginatorDetails", "companyName"] : ["caseDetails", "connectorDetails", "companyName"],
      key: "sourceOfCase",
    },
    {
      title: "Mobile Number",
      dataIndex: ["contactDetails", "mobileNumber"],
      key: "mobileNumber",
      render: colMobileNumber,
    },
    {
      title: "Case Created Date",
      dataIndex: "caseCreatedDate",
      key: "caseCreatedDate",
      width: 180,
      render: colCaseCreatedDate,
    },
    {
      title: "Days Open",
      dataIndex: "daysOpen",
      key: "daysOpen",
      width: 30,
    },
    {
      title: "Assign to Sales User",
      dataIndex: lead.activeKey === "PRE_LENDER" ? "assignedToSalesUserName" : ["caseDetails", "assignedToUserDetails", "firstName"],
      key: "assignedSalesUserName",
      width: 30,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      align: "center",
      width: 60,
      render: colAction,
    },
  ];

  /* other functions */
  const refreshLead = () => {
    //dispatch(setFilterData({}));
    dispatch(setRestFilterData());
    dispatch(setActiveKey("PRE_LENDER"));
    dispatch(setPreLenderCase("ALL"));
    if (tenantInfo?.businessProductType === "INSURANCE") {
      dispatch(setPostLenderCase("LOGGED"));
    } else {
      dispatch(setPostLenderCase("ASSIGNED"));
    }
    //setSelectedFilterData({});
    dispatch(setIsShowFilter(false));
    form.setFieldsValue({ searchBox: "" });
    dispatch(setLeadSearchParam(""));
    dispatch(setLeadPageNo(1));
    // getAllLeadsData(1, lead.pageSize, "", {});
  };

  const goToLeadEdit = (item: any) => {
    let caseId = lead?.activeKey == "PRE_LENDER" ? item?.caseId : item?.caseDetails?.caseId;
    if (
      (item?.loanProductId?.length ?? 0) == 0 ||
      item?.productVersion != "V2"
    ) {
      history.push(`/lead/edit/${caseId}`);
    } else {
      goToLeadDetails(item);
    }
  };

  const goToLeadDetails = (item: any) => {
    let caseId = lead?.activeKey == "PRE_LENDER" ? item?.caseId : item?.caseDetails?.caseId;
    history.push(`/lead/details/${caseId}`);
  };

  const handleClick = (caseId) => {
    API_SERVICE.getWhatsAppDocumentValidate(caseId)
      .then(({ data }) => {
        if (data.payload.validToSend) {
          setIsModalWhatsappVisible(true);
        } else {
          setIsModalWhatsappVisible(false);
          setSuccessMessage(data.payload.message);
          setIsSuccessModalVisible(true);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getShareLinkId = (item: any) => {
    let caseId = lead.activeKey === "PRE_LENDER" ? item?.caseId : item?.caseDetails?.caseId
    API_SERVICE.getShareLinkId(caseId)
      .then(({ data }) => {
        navigator.clipboard.writeText(data?.payload);
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  const resetCounts = () => {
    setCounts((counts) => ({
      ...counts,
      totalCases: 0,
      totalCasesInLeadState: 0,
      totalCasesInProspectState: 0,
      totalCasesInVisitState: 0,
      totalCasesInDocumentState: 0,
      totalLenderAssignedCases: 0,
      totalLenderLoggedInCases: 0,
      totalLenderSanctionedCases: 0,
      totalLenderRejectedCases: 0,
      totalLenderWithdrawnCases: 0,
      totalLenderPendencyCases: 0,
      totalLenderDisbursedCases: 0,
      totalInsurerPolicyIssued: 0,
      totalInsurerPolicyCounterOffer:0,
      totalInsurerPolicyUnderwritingReject:0,
    }));
  };

  const getAllLeadsData = (
    pageNo: number = 1,
    pageSize: number = 10,
    searchValue: any = null,
    //caseStatus: string = lead.preLenderCase,
    filter: any = lead.filterData
  ) => {
    let params = {};
    if (
      lead.activeKey === "PRE_LENDER" &&
      (lead.preLenderCase === "VISIT" ||
        lead.preLenderCase === "LEAD" ||
        lead.preLenderCase === "DOCUMENTATION" ||
        lead.preLenderCase === "PROSPECT")
    ) {
      params = {
        ...filter,
        searchParam: searchValue,
        status: lead.preLenderCase,
      };
    } else {
      params = {
        ...filter,
        searchParam: searchValue,
      };
    }

    if (lead.activeKey === "PRE_LENDER")
      getPreLenderData(pageNo, pageSize, params);
    else if (lead.activeKey === "POST_LENDER")
      getPostLenderData(lead.postLenderCase, pageNo, pageSize, params);
  };

  const getPreLenderData = (pageNo, pageSize, params) => {
    setLoading(true);
    setAllLeadsData([]);
    API_SERVICE.getLeadsByFilter(pageNo, pageSize, params)
      .then(({ data }) => {
        if (data?.payload?.content?.length > 0) {
          mapLeadsData(data);
        } else {
          mapLeadsData(data);
          notification.error({ message: "No data found." });
        }
      })
      .catch((error) => {
        mapLeadsData([]);
        notification.error({ message: API_SERVICE.handleErrors(error) });
      })
      .finally(() => setLoading(false));
  };

  const getPostLenderData = (caseStatus, pageNo, pageSize, params) => {
    setLoading(true);
    setAllLeadsData([]);
    API_SERVICE.getLeadsByFilterPostCase(caseStatus, pageNo, pageSize, params)
      .then(({ data }) => {
        if (data?.payload?.content) {
          mapLeadsData(data);
        }
      })
      .catch((error) => {
        mapLeadsData([]);
        notification.error({ message: API_SERVICE.handleErrors(error) });
      })
      .finally(() => setLoading(false));
  };

  const mapLeadsData = (data: any) => {
    //setCheckedListFilter(data?.payload);
    resetCounts();
    setCounts((counts) => ({
      ...counts,
      totalCases: data?.payload?.totalCases ?? 0,
      totalCasesInLeadState: data?.payload?.totalCasesInLeadState ?? 0,
      totalCasesInProspectState: data?.payload?.totalCasesInProspectState ?? 0,
      totalCasesInVisitState: data?.payload?.totalCasesInVisitState ?? 0,
      totalCasesInDocumentState: data?.payload?.totalCasesInDocumentState ?? 0,
      totalLenderAssignedCases: data?.payload?.totalLenderAssignedCases ?? 0,
      totalLenderLoggedInCases: data?.payload?.totalLenderLoggedInCases ?? 0,
      totalLenderSanctionedCases:
        data?.payload?.totalLenderSanctionedCases ?? 0,
      totalLenderRejectedCases: data?.payload?.totalLenderRejectedCases ?? 0,
      totalLenderWithdrawnCases: data?.payload?.totalLenderWithdrawnCases ?? 0,
      totalLenderPendencyCases: data?.payload?.totalLenderPendencyCases ?? 0,
      totalLenderDisbursedCases: data?.payload?.totalLenderDisbursedCases ?? 0,
      totalInsurerPolicyIssued: data?.payload?.totalInsurerPolicyIssued ?? 0,
      totalInsurerPolicyCounterOffer:data?.payload?.totalInsurerPolicyCounterOffer ?? 0,
      totalInsurerPolicyUnderwritingReject:data?.payload?.totalInsurerPolicyUnderwritingReject ?? 0,
    }));
    setTotalRecords(data?.payload?.totalElements);
    setAllLeadsData(data?.payload?.content);
    setIsWhatsappEnable(data?.payload?.whatsappEnabled);
  };

  const handlePaginationChange = (pagination) => {
    let page = pagination.pageSize !== lead.pageSize ? 1 : pagination.current;
    dispatch(setLeadPageNo(page));
    dispatch(setLeadPageSize(pagination.pageSize));
    getAllLeadsData(page, pagination.pageSize, lead.searchParam);
    window.scrollTo(0, 0);
  };

  const handleMobilePaginationChange = (current: any, pageSize: any) => {
    dispatch(setLeadPageNo(current));
    dispatch(setLeadPageSize(pageSize));
    getAllLeadsData(current, pageSize, lead.searchParam);
    window.scrollTo(0, 0);
  };

  const handlePreLenderTagChange = (i: number, value: string) => {
    dispatch(setLeadPageNo(1));
    dispatch(setPreLenderCase(value));
  };

  const handlePostLenderTagChange = (i: number, value: string) => {
    dispatch(setLeadPageNo(1));
    dispatch(setPostLenderCase(value));
  };

  const activeTabChange = (key) => {
    setAllLeadsData([]);
    dispatch(setLeadPageNo(1));
    dispatch(setActiveKey(key));
  };

  const getColumns = () => {
    const tmpCol = tenantInfo?.businessProductType === "INSURANCE"
        ? columnsInsurence
        : lead.activeKey === "PRE_LENDER" ? preLenderColumns : columns;;

    const co = tmpCol.filter(
      (col) =>
        col.key !==
        ("VISIT" !== lead.preLenderCase && lead.activeKey === "PRE_LENDER"
          ? ""
          : "currDropOffStage")
    );
    const co1 = co?.filter(
      (col) =>
        col.key !==
        (lead.preLenderCase == "ALL" && lead.activeKey === "PRE_LENDER"
          ? ""
          : "allLenderStages")
    );
    return co1;
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({}),
  };

  const searchAssignTo = (ev: any) => {
    if (ev.length != 0) {
      const params = {
        name: ev,
      };
      API_SERVICE.getUsersWithRole(params).then(({ data }) => {
        if (data) {
          setAssignToData(data.payload);
        }
      });
    }
  };

  const selectAssignTo = (item, opt) => {
    setSelectedAssignTo(opt);
  };

  const assignSalesUserToLead = () => {
    if (undefined === selectedRowKeys || selectedRowKeys?.length === 0) {
      notification.error({ message: "Please select a lead to approve." });
      return;
    }
    if (
      undefined === selectedAssignTo ||
      null === selectedAssignTo ||
      undefined === selectedAssignTo?.userId ||
      null === selectedAssignTo.userId
    ) {
      notification.error({ message: "Please assignee to assign a lead." });
      return;
    }
    if (
      selectedRowKeys &&
      selectedRowKeys?.length > 0 &&
      selectedAssignTo &&
      selectedAssignTo?.userId
    ) {
      //const tempRowKey = selectedRowKeys.map(id=>{return `${id}1`})
      const params = {
        dsaCaseIds: selectedRowKeys,
        salesUserId: selectedAssignTo?.userId,
      };
      setSelectedCaseIds(selectedRowKeys);
      API_SERVICE.assignSalesUserToLead(params)
        .then(({ data }) => {
          if (data) {
            let tmp = [""];
            tmp.push("salesUserId");
            setSelectedRowKeys([]);
            //findErrorCaseIds(params)
            if (data.payload.errorCases) {
              notification.error({ message: data.payload.message });
            } else {
              notification.success({ message: data.payload.message });
            }
            form.resetFields(tmp);
            console.log(errorCaseIds, selectedCaseIds);
            getAllLeadsData(lead.pageNo, lead.pageSize, lead.searchParam);
          }
          if (data.payload.errorCases) {
            setErrorDisplay(true);
            const tmpErrorCaseId = data.payload.errorCases;
            const errorCaseId = data.payload.errorCases; //tmpErrorCaseId.map(caseId=>{return caseId.substr(0,(caseId.length)-1)})
            setErrorCaseIds(errorCaseId);
            console.log(errorCaseIds, selectedCaseIds);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const handleModalOk = () => {
    const caseId = lead?.activeKey == "PRE_LENDER" ? selectedLead?.caseId : selectedLead.caseDetails.caseId;
    API_SERVICE.sendWhatsAppMessage(caseId)
      .then(({ data }) => {
        setIsModalWhatsappVisible(false);
        if (data.payload) {
          notification.success({ message: data.payload });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const handleModalCancel = () => {
    setIsModalWhatsappVisible(false);
  };
  const handleModalmessage = () => {
    setIsSuccessModalVisible(false);
  };

  const customStyles: any = { "--bgColor": appColors?.appPrimaryColor ?? "" };
  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
  };

  const whatsapp = () => {
    return (
      <Modal
        centered
        width={450}
        visible={isModalWhatsappVisible}
        footer={null}
        closable={false}
      >
        <div
          style={{
            paddingBottom: "40px",
            paddingTop: "40px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "35px",
              marginLeft: "5px",
              fontSize: "medium",
            }}
          >
            Is {selectedLead?.contactDetails?.mobileNumber} the valid contact
            number? <br />
            if not, please update lead details.
          </p>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <Button
              className="dynamic-btn-primary"
              style={
                {
                  "--btnColor": appColors?.appPrimaryColor ?? "",
                  width: "155px",
                  height: "40px",
                  borderRadius: "8px",
                } as any
              }
              type="primary"
              onClick={handleModalOk}
            >
              Confirm
            </Button>
            <Button
              className="dynamic-btn-default"
              type="default"
              style={
                {
                  "--borderColor": appColors?.appSecondaryColor ?? "",
                  width: "155px",
                  height: "40px",
                  borderRadius: "8px",
                } as any
              }
              onClick={handleModalCancel}
            >
              Back
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const message = () => {
    return (
      <Modal
        centered
        width={450}
        visible={isSuccessModalVisible}
        footer={null}
        closable={false}
      >
        {" "}
        <p
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "15px",
            marginLeft: "5px",
            fontSize: "medium",
          }}
        >
          {successMessage}
        </p>
        <Button
          className="dynamic-btn-default"
          style={
            {
              "--borderColor": appColors?.appSecondaryColor ?? "",
              width: "155px",
              height: "40px",
              borderRadius: "8px",
              margin: "20px",
              marginLeft: "110px",
            } as any
          }
          type="default"
          onClick={handleModalmessage}
        >
          OK
        </Button>
      </Modal>
    );
  };

  const bulkAssignError = () => {
    return (
      <Modal
        centered
        //style={{top:20}}
        width={350}
        footer={null}
        closable={false}
        visible={errorDisplay}
      >
        <div
          style={{ display: "grid", alignItems: "center", marginTop: "5px" }}
        >
          {/**/}
          <p>
            There was an error in assigning some cases. Please retry assignment
            for the cases highlighted on screen
          </p>
          <Button
            style={{
              bottom: "15px",
              justifyContent: "center",
              left: "70px",
            }}
            size="large"
            className="button-submit"
            htmlType="submit"
            onClick={() => setErrorDisplay(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    );
  };

  const menuList = (
    <Menu
      selectable
      className="menu-list"
      style={
        {
          "--bgColor": appColors?.appPrimaryLightColor ?? "",
          "--color": appColors?.appPrimaryColor ?? "",
        } as any
      }
      onClick={(item: any) => {
        if (item?.key == "BulkUpload") {
          history.push("/lead/bulkUpload");
        } else {
          history.push("/lead/bulkUpdate");
        }
      }}
      items={[
        {
          key: "BulkUpload",
          label: "Bulk Upload",
        },
        {
          key: "BulkUpdate",
          label: "Status Update",
          style: {
            display:
              userRolePermission["ApprovePayout"] ||
                userRolePermission["Admin"] ||
                getUser()?.userDetails?.department?.toLowerCase() == "back office"
                ? ""
                : "none",
          },
        },
      ]}
    />
  );

  const renderTabs = () => {
    return (
      <Tabs
        activeKey={lead.activeKey}
        onChange={(key) => activeTabChange(key)}
        style={{ marginBottom: "20px" }}
      >
        <TabPane
          tab={
            tenantInfo?.businessProductType === "INSURANCE"
              ? "Pre Insurer"
              : "Pre Lender"
          }
          key="PRE_LENDER"
        >
          {preLenderCases.map((item: any, i: number) => (
            <CheckableTag
              className="custom-checkable"
              style={customStyles}
              key={item.key}
              checked={item.key === lead.preLenderCase}
              // onChange={() => dispatch(setPreLenderCase(item.key))}
              onChange={(checked) => handlePreLenderTagChange(i, item.key)}
            >
              {item.value}
            </CheckableTag>
          ))}
        </TabPane>
        <TabPane
          tab={
            tenantInfo?.businessProductType === "INSURANCE"
              ? "Post Insurer"
              : "Post Lender"
          }
          key="POST_LENDER"
        >
          {postLenderCases.map((item: any, i: number) => (
            <CheckableTag
              className="custom-checkable"
              style={customStyles}
              key={item.key}
              checked={item.key === lead.postLenderCase}
              // onChange={() => dispatch(setPostLenderCase(item.key))}
              onChange={(checked) => handlePostLenderTagChange(i, item.key)}
            >
              {item.value}
            </CheckableTag>
          ))}
        </TabPane>
      </Tabs>
    );
  };

  const renderLeadListItem = (item: any) => {
    let value = {} as any;
    value = prospectOnboardingStatus?.find((i: any) => {
      return i?.key === item?.currDropOffStage;
    });
    const dropOffStageValue = value?.value;
    return (
      <>
        <List.Item style={{ padding: "0px 12px" }}>
          <Card>
            <div
              onClick={() => {
                goToLeadDetails(item);
              }}
            >
              <Row gutter={24}>
                <Col span={12}>
                  <label className="leadlist-title">Lead</label>
                  <br />
                  {item.contactDetails.firstName}
                </Col>
                <Col span={12} className="text-right">
                  {item.loanType}
                </Col>
              </Row>
              <Row gutter={24} className="title-space">
                <Col span={10}>
                  <label className="leadlist-title"> Drop Off Stage</label>
                  <br />
                  <p>{dropOffStageValue} </p>
                </Col>
                <Col span={6}>
                  <label className="leadlist-title"> Stage</label>
                  <br />
                  {item?.caseDetails?.caseStatus}
                </Col>
                <Col span={6} style={{ paddingLeft: "38px" }}>
                  <label className="leadlist-title">Version</label>
                  <br />
                  {item?.productVersion}
                </Col>
              </Row>
            </div>
            <Row gutter={14} className="title-space">
              <Col span={10}>
                <label className="leadlist-title">Created</label>
                <br />
                {moment(item?.caseCreatedDate)
                  .locale("en")
                  .format("MMM, DD, YYYY HH:mm")}
              </Col>
              <Col span={6} className="text-right">
                <label className="leadlist-title">Days Open</label>
                <br />
                {item.daysOpen}
              </Col>
              <Col
                span={6}
                className="text-right"
                style={{ paddingLeft: "40px" }}
              >
                <label className="leadlist-title">Share</label>
                <br />
                <Space
                  className="action-button"
                  size="small"
                  style={{ gap: "0px" }}
                >
                  <Button
                    className="custome-btn-icon-secondary"
                    style={
                      { "--bgColor": appColors?.appSecondaryColor ?? "" } as any
                    }
                    size="small"
                    hidden={
                      "LEAD" === lead.preLenderCase &&
                      "PRE_LENDER" === lead.activeKey
                    }
                    onClick={() => getShareLinkId(item)}
                    icon={<IconShare />}
                  />
                  {isWhatsappEnable && (
                    <Button
                      size="small"
                      hidden={!item?.isWhatsAppEnabled}
                      onClick={() => {
                        setSelectedLead(item);
                        handleClick(item?.caseDetails.caseId);
                      }}
                      icon={
                        <img
                          src={whatsappIcon}
                          alt=""
                          height={24}
                          style={{ marginTop: "-4px" }}
                        />
                      }
                    />
                  )}
                </Space>
              </Col>
            </Row>
          </Card>
        </List.Item>
      </>
    );
  };

  return (
    <>
      {bulkAssignError()}
      {whatsapp()}
      {message()}
      <div className="content-box">
        <Form form={form}>
          <Row className="lead-filter-row">
            <Col
              xs={14}
              sm={16}
              md={13}
              lg={13}
              className="filter-bar left"
              span={13}
            >
              <Form.Item name="searchBox">
                <Input
                  size="large"
                  className="search-box"
                  placeholder="Search"
                  onChange={debounce((event) => {
                    if (event?.target?.value?.length > 2) {
                      dispatch(setLeadSearchParam(event.target.value));
                      dispatch(setLeadPageNo(1));
                    } else if (event?.target?.value?.length == 0) {
                      dispatch(setLeadSearchParam(""))
                      dispatch(setLeadPageNo(1));
                    }
                  }, 600)}
                  allowClear
                  prefix={<FaSearch />}
                />
              </Form.Item>
            </Col>
            <Col
              span={4}
              xs={24}
              sm={16}
              md={4}
              lg={4}
              className={isMobile ? "filter-bar " : "filter-bar right"}
              style={{
                marginLeft: isMobile ? "" : "-62px",
                marginTop: isMobile ? "" : "-7px",
              }}
            >
              <Space style={{ gap: "15px" }}>
                <Button
                  type="primary"
                  className="filter-btn"
                  onClick={() => dispatch(setIsShowFilter(!lead.isShowFilter))}
                >
                  <img
                    src={blackFilterIcon}
                    alt=""
                    style={{ marginLeft: "-7px" }}
                  />
                  <div
                    className="badge-green"
                    hidden={
                      !(
                        lead.filterData?.connectorId ||
                        lead.filterData?.loanProductIds||//lead.filterData?.loanType ||
                        lead.filterData?.location ||
                        lead.filterData?.managerHierarchy
                      )
                    }
                  />
                </Button>

                <Tooltip title="Refresh">
                  <Button
                    type="primary"
                    className="refresh-button"
                    onClick={() => refreshLead()}
                  >
                    {" "}
                    <img
                      src={blackRefereshIcon}
                      alt=""
                      style={{ marginLeft: "-10px" }}
                    />
                  </Button>
                </Tooltip>
              </Space>
            </Col>
            <Col
              span={8}
              xs={24}
              sm={16}
              md={4}
              lg={4}
              style={{
                marginTop: isMobile ? "" : "30px",
                marginLeft: isMobile ? "4px" : "",
                marginBottom: isMobile ? "18px" : "",
              }}
            >
              <Space>
                <div style={{ marginLeft: isMobile ? "-10px" : "" }}>
                  <Button
                    type="primary"
                    className="add-button dynamic-btn-primary"
                    style={btnPrimaryStyles}
                    hidden={
                      !(
                        userRolePermission["CreateLead"] ||
                        userRolePermission["Admin"]
                      )
                    }
                    onClick={() => history.push("/lead/add-lead")}
                  >
                    Add Lead
                  </Button>
                </div>

                {tenantInfo?.businessProductType !== "INSURANCE" && (
                  <Dropdown
                    overlay={menuList}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: false }}
                  >
                    <Button
                      type="primary"
                      className="add-button dynamic-btn-primary"
                      style={btnPrimaryStyles}
                      hidden={
                        !(
                          userRolePermission["CreateLead"] ||
                          userRolePermission["Admin"]
                        )
                      }
                    >
                      Bulk Operations
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                )}
              </Space>
            </Col>
          </Row>
        </Form>

        {lead.isShowFilter && (
          <LeadFilter
            key="NEW_LEAD_FILTER"
          //actionApplyFilter={actionApplyFilter}
          //setIsFilterVisible={setIsFilterVisible}
          //setSelectedFilterData={setSelectedFilterData}
          //selectedFilterData={selectedFilterData}
          //setFilterListData={setFilterListData}
          //filterListData={filterListData}
          />
        )}

        {renderTabs()}

        {isMobile ? (
          <List
            style={{ width: "100%" }}
            grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
            pagination={{
              onChange: (page, pageSize) => {
                handleMobilePaginationChange(page, pageSize);
              },
              current: lead.pageNo,
              pageSize: lead.pageSize,
              total: totalRecords,
            }}
            itemLayout="horizontal"
            dataSource={allLeadsData}
            loading={loading}
            renderItem={renderLeadListItem}
          />
        ) : (
          <Table
            className="dynamic-pagination-checkbox"
            style={customStyles}
            size="small"
            loading={loading}
            scroll={{ x: "max-content" }}
            dataSource={allLeadsData}
            rowKey={(record) => "PRE_LENDER" === lead.activeKey ? record?.caseId : record?.caseDetails?.caseId}
            rowClassName={(record: any) =>
              errorCaseIds.includes(record?.caseDetails?.caseId)
                ? "selected-row"
                : ""
            }
            rowSelection={rowSelection}
            columns={getColumns()}
            pagination={{
              current: lead.pageNo,
              pageSize: lead.pageSize,
              total: totalRecords,
            }}
            onChange={handlePaginationChange}
          />
        )}

        <Row
          gutter={[24, 24]}
          style={{ display: "flex", justifyContent: "left", marginTop: "20px" }}
        >
          <Col span={8} xs={16} xl={8}>
            <Form form={form}>
              <Form.Item label="Assign To:" name="salesUserId">
                {/* <input /> */}
                <AutoComplete
                  className="custom-auto-compleat"
                  onSearch={debounce(searchAssignTo, 1000)}
                  placeholder="Search with mobile number or name"
                  dropdownClassName="custom-option-list"
                  onSelect={selectAssignTo}
                >
                  {assignToData.map((item: any, i: any) => (
                    <Select.Option
                      key={item.userId}
                      {...item}
                      value={item.fullName}
                    >
                      <Row gutter={[16, 16]} className="assign-list">
                        <Col
                          className="left"
                          span={16}
                          style={{ marginTop: isMobile ? "35px" : "" }}
                        >
                          <span className="name">{item.fullName}</span>
                          <br />
                          <span className="city">
                            {item?.address?.city && item?.address?.city}
                          </span>
                        </Col>
                        <Col className="right" span={8}>
                          <span className="mob">
                            {item?.phoneNumber && item?.phoneNumber}
                          </span>
                        </Col>
                      </Row>
                    </Select.Option>
                  ))}
                </AutoComplete>
                &nbsp;&nbsp;&nbsp;
              </Form.Item>
            </Form>
          </Col>
          <Col
            span={3}
            xs={8}
            xl={3}
            style={{ marginTop: isMobile ? "41px" : "" }}
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="upload-btn dynamic-btn-primary"
                style={btnPrimaryStyles}
                onClick={assignSalesUserToLead}
              >
                Assign
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LeadPage;
