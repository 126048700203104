import {
  Row,
  Button,
  Col,
  Form,
  InputNumber,
  DatePicker,
  notification,
  Modal,
  Space,
  Card,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DOUBLE_REGEX, NUMBER_REGEX } from "shared/constants/AppConstants";
import { numberWithCommas } from "shared/services/Utility";
import {ReactComponent as IconAction } from "../../../../../assets/images/action.svg";
import "./index.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const DisbursedGroup: React.FunctionComponent<any> = ({
  lender,
  lenderIndex,
  payment,
  index,
  payoutDetailIndex,
  onAmountChange,
  onActionSubmit,
  leadData,
  setIsAddDisabled,
  setAddBtnErr,
  getRedirectURLLink,
  loadingUpdate,
  getDownloadLetterForICICI,
}) => {
  const [disbursedForm] = Form.useForm() as any;
  const dateFormat = "YYYY-MM-DD";
  const [showConfirmationPayout, setShowConfirmationPayout] = useState(false);
  const [finalValue, setFinalValue] = useState(null as any);
  const [loading, setLoading] = useState(false);

  const payoutType = leadData?.caseDetails?.connectorDetails?.payoutType;
  const checkTenativePayout = leadData?.caseDetails?.connectorDetails?.payoutPercentage;
  const { appColors, isMobile }: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    if (!loadingUpdate) {
      setLoading(false);
    }
  }, [loadingUpdate]);

  const finalSubmit = (value: any) => {
    const infoEntity = lender?.disbursalLenderInfoDetails?.find(
      (item: any) => item?.disbursalId == payment?.disbursalId
    );

    const tmpLender = { ...lender };
    tmpLender.disbursalLenderInfoDetails = null;
    tmpLender.disbursalLenderInfoEntity = [infoEntity];
    if (value?.payoutPercentage == 0) {
      notification.error({
        message:
          "Please ensure that payout % are filled, this field will not be editable beyond this point.",
      });
    } else {
      setLoading(true);
      onActionSubmit(tmpLender, value?.comment);
    }
  };

  const confirmationSubmit = () => {
    finalSubmit(finalValue);
    setShowConfirmationPayout(false);
  };

  const actionSubmit = (value: any) => {
    if (
      payoutType == "FIXED" &&
      value?.payoutPercentage !== checkTenativePayout && value?.payoutPercentage != 0
    ) {
      setFinalValue(value);
      setShowConfirmationPayout(true);
    } else {
      finalSubmit(value);
    }
  };

  const handleCancel = () => {
    setShowConfirmationPayout(false);
    disbursedForm.setFieldsValue({
      payoutPercentage: checkTenativePayout,
    });
  };

  const disbursedInputHandler = (value, lenderIndex, name, i) => {
    lender.disbursalLenderInfoDetails[i].lenderAmount[name] = value;
    onAmountChange();
  };

  const DatePickerInputHandler = (value, lenderIndex, name, i) => {
    lender.disbursalLenderInfoDetails[i].lenderAmount[name] = value
      ? moment(value).format("YYYY-MM-DD").toString()
      : "";
  };

  const disableDate = (current) => {
    let sanctionedDate = lender?.sanctionedDate;
    if ((sanctionedDate?.length ?? 0) > 0) {
      return current?.isAfter(moment()) || current?.isBefore(moment(sanctionedDate));
    }
    return current?.isAfter(moment()?.subtract(0, "day"))
  };

  const payoutPercentageInputHandler = (value, name, i) => {
    lender.disbursalLenderInfoDetails[i].lenderAmount[name] = value;
  };
  
  const getHeader = () => {
  setIsAddDisabled(!payment?.disbursalId);
  setAddBtnErr('Please fill current disbursement details before adding a disbursement');
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {`Disbursal #${payoutDetailIndex + 1}`}
      </div>
      <div style={{ display: isMobile ? "flex" : "grid" }} >
        {payment?.disbursalId?.length > 0 ? (
          <span style={{ fontSize: "11px" }}>
            ID:{payment?.disbursalId}&nbsp;
            {/* <img
              src={copy}
              alt=""
              width={11}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(payment?.disbursalId);
              }}
            /> */}
          </span>
        ) : (
          ""
        )}
      </div>
       {isMobile ? (
         <div style={{float:'right' ,marginTop:'-35px'}}>
          {renderDisbursedSubmitBtn()}
         </div>
       ):''}
    </>
  )};

  const renderDisbursedFormFields = () => {
    return(
         <>
            <Form.List name={`${lenderIndex}-${index}`}>
              {(fields) => (
                <>
                  <Col span={colSpan} xs={12} lg={colSpan} className="disbursed-row">
                    <Form.Item
                      name="insuranceAmount"
                      label="Insurance Amount"
                      className="decision-item"
                      initialValue={payment?.lenderAmount?.insuranceAmount}
                      rules={[
                        {
                          required: true,
                          message: "Please enter amount",
                        },
                        () => ({
                          validator(_, value) {
                            // let amountDisbursed =
                            //   lender.disbursalLenderInfoDetails.map((item) =>
                            //     Number(item.lenderAmount.disbursedAmount)
                            //   );
                            // let amountInsurance =
                            //   lender.disbursalLenderInfoDetails.map((item) =>
                            //     Number(item.lenderAmount.insuranceAmount)
                            //   );
                            // const totalDisbursed = amountDisbursed.reduce(
                            //   (a, b) => a + b,
                            //   0
                            // );
                            // const totalInsurance = amountInsurance.reduce(
                            //   (a, b) => a + b,
                            //   0
                            // );
                            // const total = totalDisbursed + totalInsurance;

                            if (!value && value != 0) {
                              return Promise.reject();
                            }
                            if (isNaN(value)) {
                              return Promise.reject(
                                "Please enter valid amount"
                              );
                            }
                            // const availbleAmount =
                            //   lender.sanctionedAmount - (total - value);
                            // if (value > availbleAmount) {
                            //   return Promise.reject(
                            //     `Please enter less amount than ${availbleAmount}`
                            //   );
                            // }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        onChange={(ev) =>
                          disbursedInputHandler(
                            ev,
                            lenderIndex,
                            "insuranceAmount",
                            index
                          )
                        }
                        disabled={payment?.lenderAmount?.isDisabled ?? false}
                        className="custom-input-number"
                        value={payment?.lenderAmount?.insuranceAmount}
                        formatter={(value) => numberWithCommas(value)}
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                        name="insuranceAmount"
                        placeholder="Insurance Amount"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={colSpan} xs={12} lg={colSpan} className="disbursed-row">
                    <Form.Item
                      name="disbursedAmount"
                      label="Disbursed Amount"
                      className="decision-item"
                      initialValue={payment?.lenderAmount?.disbursedAmount}
                      rules={[
                        {
                          required: true,
                          message: "Please enter amount",
                        },
                        {
                          pattern: NUMBER_REGEX,
                          message: "Please enter valid amount",
                        },
                        () => ({
                          validator(_, value) {
                            let amountDisbursed =
                              lender.disbursalLenderInfoDetails.map((item) =>
                                Number(item.lenderAmount.disbursedAmount)
                              );
                            // let amountInsurance =
                            //   lender.disbursalLenderInfoDetails.map((item) =>
                            //     Number(item.lenderAmount.insuranceAmount)
                            //   );
                            const totalDisbursed = amountDisbursed.reduce(
                              (a, b) => a + b,
                              0
                            );
                            // const totalInsurance = amountInsurance.reduce(
                            //   (a, b) => a + b,
                            //   0
                            // );
                            const total = totalDisbursed;

                            if (value == 0) {
                              return Promise.reject(
                                "Please enter valid amount"
                              );
                            }
                            if (isNaN(value)) {
                              return Promise.reject(
                                "Please enter valid amount"
                              );
                            }
                            const availbleAmount =
                              lender.sanctionedAmount - (total - value);
                            if (value > availbleAmount) {
                              return Promise.reject(
                                `Please enter less amount than ${availbleAmount}`
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        onChange={(ev) =>
                          disbursedInputHandler(
                            ev,
                            lenderIndex,
                            "disbursedAmount",
                            index
                          )
                        }
                        disabled={payment?.lenderAmount?.isDisabled ?? false}
                        className="custom-input-number"
                        value={payment?.lenderAmount?.disbursedAmount}
                        formatter={(value) => numberWithCommas(value)}
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                        name="disbursedAmount"
                        placeholder="Disbursed Amount"
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
        </Form.List>
        <Col span={colSpan} xs={12} lg={colSpan} className="disbursed-row">
              <Form.Item
                name="disbursalDate"
                label="Disbursed Date"
                className="decision-item"
                initialValue={
                  payment?.lenderAmount?.disbursalDate
                    ? moment(payment?.lenderAmount?.disbursalDate)
                    : ""
                }
                rules={[{ required: false }]}
              >
                <DatePicker
                  onChange={(ev) => {
                    DatePickerInputHandler(
                      ev,
                      lenderIndex,
                      "disbursalDate",
                      index
                    );
                  }}
                  disabledDate={disableDate}
                  style={{
                    width: isMobile ? '170px':'98%',
                    marginLeft: "3px",
                    marginBottom: "5px",
                  }}
                  format={dateFormat}
                  className="custom-input"
                  placeholder="Disbursal Date"
                />
              </Form.Item>
            </Col>
            {leadData?.sourceOfCase === "CONNECTOR" && (
              <Col span={colSpan} xs={12} lg={colSpan} className="disbursed-row">
                <Form.Item
                  style={{paddingLeft: isMobile ? '10px' : '', width: isMobile? 'auto' : ''}}
                  name="payoutPercentage"
                  label="Payout %"
                  className="decision-item"
                  initialValue={payment?.lenderAmount?.payoutPercentage}
                  rules={[
                    {
                      required: true,
                      message: "Please enter payout%",
                    },
                    {
                      pattern: DOUBLE_REGEX,
                      message: "Please enter valid payout%",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value && value != 0) {
                          return Promise.reject();
                        }
                        if (isNaN(value)) {
                          return Promise.reject("Please enter valid payout %");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    value={payment?.lenderAmount?.payoutPercentage}
                    // disabled={payment?.lenderAmount?.isDisabled ?? false}
                    onChange={(ev: any) => {
                      payoutPercentageInputHandler(
                        ev,
                        "payoutPercentage",
                        index
                      );
                    }}
                    className="custom-input-number"
                  />
                </Form.Item>
              </Col>
            )} 
            <Col span={3}>
              {!isMobile ? (renderDisbursedSubmitBtn()) : ('')}   
            </Col>
         </>
    )
  }

  const renderDisbursedSubmitBtn = () => {
    return (
      <Button
      type="primary"
      htmlType="submit"
      className={isMobile ? 'mobile-view-icon-btn' : 'next-btn dynamic-btn-primary'}
      style={customStylesSubmitBtnPrimary}
      icon={isMobile ? <IconAction/> : ''} 
      >
        { isMobile?'' : 'Submit' }
      </Button>
    )
  }

  const confirmationPayout = () => {
    const customStylesBtnPrimary: any = { '--btnColor': appColors?.appPrimaryColor ?? "", width: "85px", height: "35px" };
    const customStylesBtn: any = { '--borderColor': appColors?.appSecondaryColor ?? "", width: "85px", height: "35px" };
    return (
      <Modal
        width={350}
        visible={showConfirmationPayout}
        style={{ marginBottom: 230 }}
        okText="Yes"
        cancelText="No"
        title=""
        centered
        closable={false}
        maskClosable={false}
        footer={false}
      >
        <div style={{ padding: "15px" }}>
          <p style={{ fontSize: "17px" }}>
            This is a fixed payout connector. Are you sure you want to edit the
            payout% ?
          </p>
          <Space style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              className="dynamic-btn-primary"
              style={customStylesBtnPrimary}
              onClick={() => {
                confirmationSubmit();
              }}
            >
              OK
            </Button>
            <Button
              style={customStylesBtn}
              className="dynamic-btn-default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </Modal>
    )
  }

  const customStylesSubmitBtnPrimary: any = {
    '--btnColor': appColors?.appPrimaryColor ?? "", 'width': "80px",
    'borderRadius': "9px",
    'marginBottom': "10px",
  };
  const colSpan = leadData?.sourceOfCase === "CONNECTOR" ? 4 : 5;

  return (
    <div key={payoutDetailIndex} className="disbursal-item">
      <Form form={disbursedForm} onFinish={actionSubmit} layout="vertical">
        <div style={{ padding: "5px" }}>
          <Row
            className="selected-lender-row"
            gutter={[5, 5]}
            style={{ marginTop: "5px" }}
          >
            <Col span={5} xs={24} lg={5}>
            {isMobile ? (
              <Card title={getHeader()}   bordered={true} style={{ width: 'auto'}}>
                <div style={{ display: 'ruby' }}>
                  {renderDisbursedFormFields()}
                </div>
              </Card>
           ) : (getHeader())}
            </Col>
            {!isMobile ? (renderDisbursedFormFields()) : ('')}
          </Row>
        </div>
        {/* <Row gutter={[8, 8]} style={{ display: "flex", justifyContent: "end" }}>
           <Col span={15}>
            <Form.Item
              name="comment"
              initialValue={payment?.lenderAmount?.comments ?? ""}
              rules={[{ required: false, message: "Please enter comment" }]}
            >
              <TextArea
                name="comment"
                showCount
                maxLength={500}
                className="custom-textarea"
                placeholder="Comment"
                onChange={(ev) =>
                  disbursedInputHandler(
                    ev.target.value,
                    lenderIndex,
                    "comments",
                    index
                  )
                }
              />
            </Form.Item>
          </Col> 
           <Col span={4}>
            <Button
              type="primary"
              htmlType="submit"
              className="next-btn"
              style={{ width: "95%", borderRadius: "9px" }}
              // loading={loading}
            >
              Submit
            </Button>
          </Col> *
        </Row> */}
      </Form>
      {showConfirmationPayout && confirmationPayout()}
    </div>
  );
};

export default DisbursedGroup;