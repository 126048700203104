import React, { useEffect, useState } from "react";
import { Menu, MenuProps, notification } from "antd";
import {ReactComponent as Dashboard} from "../../../../assets/images/sidebar/DashBoard.svg";
import {ReactComponent as Lead} from "../../../../assets/images/sidebar/Lead.svg";
import {ReactComponent as Connector} from "../../../../assets/images/sidebar/Connector.svg";
import {ReactComponent as Offers} from "../../../../assets/images/sidebar/offers.svg";
import {ReactComponent as Notices} from "../../../../assets/images/sidebar/Notices.svg";
import {ReactComponent as Attendance} from "../../../../assets/images/sidebar/attendance.svg";
import {ReactComponent as IconHelp} from "../../../../assets/images/sidebar/Help.svg";
import {ReactComponent as Employee} from "../../../../assets/images/sidebar/Employee.svg";
import {ReactComponent as Locations} from "../../../../assets/images/sidebar/Locations.svg";
import {ReactComponent as Department} from "../../../../assets/images/sidebar/Department.svg";
import {ReactComponent as Permissions} from "../../../../assets/images/sidebar/Permissions.svg";
import {ReactComponent as LoanProduct} from "../../../../assets/images/sidebar/Loan Product.svg";
import {ReactComponent as Lender} from "../../../../assets/images/sidebar/Lender.svg";
import {ReactComponent as Reports} from "../../../../assets/images/sidebar/Reports.svg";
import {ReactComponent as Payouts} from "../../../../assets/images/sidebar/Payouts.svg";
import {ReactComponent as DecisioningAudit} from "../../../../assets/images/sidebar/dec-audit.svg";
import {ReactComponent as VisitingCard} from "../../../../assets/images/sidebar/Visiting card.svg";
import {ReactComponent as Logout} from "../../../../assets/images/sidebar/Logout.svg";
import {ReactComponent as Designation} from "../../../../assets/images/sidebar/Designation.svg";
import { useSelector } from "react-redux";
import API_SERVICE from "shared/services/api-service";
import "./SideBar.scss";
import { hasUserRole } from "shared/services/Utility";
import {
  ATTENDANCE,
  CONNECTORS,
  DASHBOARD_ROUTE,
  DEPARTMENT,
  DESIGNATION,
  EMPLOYEES,
  HELP,
  LEAD,
  LENDERS,
  INSURER,
  LOAN_PRODUCT,
  LOCATION,
  NOTICES,
  OFFERS,
  PAYOUT_REQUEST,
  PERMISSIONS,
  REPORT,
  Visiting_Crads,
  LOGOUT,
  Decisioning_Audit
} from "shared/constants/RouteConstants";
import { useHistory } from "react-router-dom";
import { RootState } from "shared/redux/store";
interface IDesktopNavbar {
  closeNavHandler?: any;
  collapsedSlider?: boolean;
  businessDocumentId: string;
}

type MenuItem = Required<MenuProps>["items"][number];

export default function SideBar(props: IDesktopNavbar) {
  const { closeNavHandler, collapsedSlider, businessDocumentId } = props;

  const [selectedKeys, setSelectedKeys] = useState(DASHBOARD_ROUTE);
  const [documentData, setDocumentData] = useState(null as any);
  const [docType, setDocType] = useState(null as any);
  
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  
  const history = useHistory();
  const userRolePermission = hasUserRole();

  useEffect(() => {
    const path = new URL(window.location.href).pathname;
    setSelectedKeys(path);
  }, [window.location.href]);
  
  useEffect(() => {
    if((businessDocumentId?.length ?? 0) > 0){ 
      getDocUpload(businessDocumentId);
    }
  }, [businessDocumentId]);

  const getDocUpload = (Id: any) => {
    API_SERVICE.getDocDetails(Id)
      .then(({ data }) => {
        localStorage.setItem("getlogoDetail", JSON.stringify(data?.payload));
        setDocumentData(data?.payload?.documentDetails);
        setDocType(data?.payload?.fileExtn);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

 const setIcon = (Icon: any, strokeFill: boolean = false) => {
    return <Icon height={18} width={18} className={strokeFill ? "stroke-fill" : ""} />;
  };

  const handelOnClick = (path: string) => {
    closeNavHandler && closeNavHandler();
    history.push(path);
  };

  const dashboard = {
    key: DASHBOARD_ROUTE,
    label: "Dashboard",
    icon: setIcon(Dashboard),
    style:
      userRolePermission["ReadOnly"] ||
      userRolePermission["CreateLead"] ||
      userRolePermission["UpdateLead"] ||
      userRolePermission["Admin"]
        ? {}
        : { display: "none" },
    onClick: () => handelOnClick(DASHBOARD_ROUTE),
  };

  const lead = {
    key: LEAD,
    label: "Lead",
    icon: setIcon(Lead),
    style:
      userRolePermission["ReadOnly"] ||
      userRolePermission["CreateLead"] ||
      userRolePermission["UpdateLead"] ||
      userRolePermission["Admin"]
        ? {}
        : { display: "none" },
    onClick: () => handelOnClick(LEAD),
  };

  const connectors = {
    key: CONNECTORS,
    label: "Connectors",
    icon: setIcon(Connector),
    style:
      userRolePermission["ReadOnly"] ||
      userRolePermission["CreateLead"] ||
      userRolePermission["UpdateLead"] ||
      userRolePermission["Admin"]
        ? {}
        : { display: "none" },
    onClick: () => handelOnClick(CONNECTORS),
  };

  const offers = {
    key: OFFERS,
    label: "Offers",
    icon: setIcon(Offers),
    style:
      userRolePermission["ReadOnly"] ||
      userRolePermission["CreateLead"] ||
      userRolePermission["UpdateLead"] ||
      userRolePermission["UpdateOperations"] ||
      userRolePermission["CreateOperations"] ||
      userRolePermission["Admin"]
        ? {}
        : { display: "none" },
    onClick: () => handelOnClick(OFFERS),
  };

  const notices = {
    key: NOTICES,
    label: "Notices",
    icon: setIcon(Notices, true),
    style:
      userRolePermission["ReadOnly"] ||
      userRolePermission["CreateLead"] ||
      userRolePermission["UpdateLead"] ||
      userRolePermission["UpdateOperations"] ||
      userRolePermission["CreateOperations"] ||
      userRolePermission["Admin"]
        ? {}
        : { display: "none" },
    onClick: () => handelOnClick(NOTICES),
  };

  const attendance = {
    key: ATTENDANCE,
    label: "Attendance",
    icon: setIcon(Attendance),
    onClick: () => handelOnClick(ATTENDANCE),
  };

  const Help = {
    key: HELP,
    label: "Help",
    icon: setIcon(IconHelp),
    onClick: () => handelOnClick(HELP),
  };

  const userManagement = {
    key: "USER_MANAGEMENT",
    label: collapsedSlider ? "" : <p className="sidebar-menu-header">USER MANAGEMENT</p>,
    style:
      userRolePermission["Admin"] ||
      userRolePermission["CreateUser"] ||
      userRolePermission["UpdateUser"]
        ? {}
        : { display: "none" },
    children: [
      {
        key: EMPLOYEES,
        label: "User",
        icon: setIcon(Employee),
        onClick: () => handelOnClick(EMPLOYEES),
      },
      {
        key: LOCATION,
        label: "Locations",
        icon: setIcon(Locations),
        onClick: () => handelOnClick(LOCATION),
      },
      {
        key: DEPARTMENT,
        label: "Departments",
        icon: setIcon(Department),
        onClick: () => handelOnClick(DEPARTMENT),
      },
      {
        key: DESIGNATION,
        label: "Designations",
        icon: setIcon(Designation),
        onClick: () => handelOnClick(DESIGNATION),
      },
    ],
    type: "group",
  };

  const operationAdmin = {
    key: "OPERATION_ADMIN",
    label: collapsedSlider ? "" : <p className="sidebar-menu-header">OPERATION ADMIN</p>,
    style:
      userRolePermission["Admin"] ||
      userRolePermission["CreateOperations"] ||
      userRolePermission["UpdateOperations"] ||
      userRolePermission["UserLevelReports"] ||
      userRolePermission["Reports"]
        ? {}
        : { display: "none" },
    children: [
      {
        key: PERMISSIONS,
        label: "Permissions",
        icon: setIcon(Permissions),
        style: userRolePermission["Admin"] ? {} : { display: "none" },
        onClick: () => handelOnClick(PERMISSIONS),
      },
      {
        key: LOAN_PRODUCT,
        label: tenantInfo?.businessProductType === "INSURANCE" ? "Products" : "Loan Products",
        icon: setIcon(LoanProduct),
        style:
          userRolePermission["CreateOperations"] ||
          userRolePermission["UpdateOperations"] ||
          userRolePermission["Admin"]
            ? {}
            : { display: "none" },
        onClick: () => handelOnClick(LOAN_PRODUCT),
      },
      tenantInfo?.businessProductType === "INSURANCE" ?
      {
        key: INSURER,
        label: "Insurer",
        icon: setIcon(Lender),
        style:
          userRolePermission["CreateOperations"] ||
          userRolePermission["UpdateOperations"] ||
          userRolePermission["Admin"]
            ? {}
            : { display: "none" },
        onClick: () => handelOnClick(INSURER),
      } : 
      {
        key: LENDERS,
        label: "Lenders",
        icon: setIcon(Lender),
        style:
          userRolePermission["CreateOperations"] ||
          userRolePermission["UpdateOperations"] ||
          userRolePermission["Admin"]
            ? {}
            : { display: "none" },
        onClick: () => handelOnClick(LENDERS),
      },
      {
        key: REPORT,
        label: "Reports",
        icon: setIcon(Reports, true),
        style:
          userRolePermission["UserLevelReports"] ||
          userRolePermission["Reports"] ||
          userRolePermission["Admin"]
            ? {}
            : { display: "none" },
        onClick: () => handelOnClick(REPORT),
      },
    ],
    type: "group",
  };

  const payouts = {
    key: "PAYOUTS",
    label: collapsedSlider ? "" : <p className="sidebar-menu-header">PAYOUTS</p>,
    style:
      (userRolePermission["ApprovePayout"] || userRolePermission["Admin"]) && 
      tenantInfo?.businessProductType !== "INSURANCE"
        ? {}
        : { display: "none" },
    children: [
      {
        key: PAYOUT_REQUEST,
        label: "Payouts",
        icon: setIcon(Payouts, true),
        onClick: () => handelOnClick(PAYOUT_REQUEST),
      },
    ],
    type: "group",
  };

  const contentManagement = {
    key: "CONTENT_MANAGEMENT",
    label: collapsedSlider ? "" : <p className="sidebar-menu-header">CONTENT MANAGEMENT</p>,
    children: [
      {
        key: Decisioning_Audit,
        label: "Decisioning Audit",
        icon: setIcon(DecisioningAudit, true),
        style: (userRolePermission["DecisioningAudit"] || userRolePermission["Admin"]) && tenantInfo?.showDecisioningAudit
          ? {}
          : { display: "none" },
        onClick: () => handelOnClick(Decisioning_Audit),
      },
      {
        key: Visiting_Crads,
        label: "Visiting Cards",
        icon: setIcon(VisitingCard, true),
        onClick: () => handelOnClick(Visiting_Crads),
      },
      {
        key: "Logout",
        label: "Logout",
        icon: setIcon(Logout, true),
        onClick: () => handleLogout(),
      },
    ],
    type: "group",
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    document.title = "OneFlo";
  };

  const items: MenuItem[] = [
    dashboard,
    lead,
    connectors,
    offers,
    notices,
    attendance,
    Help,
    userManagement,
    operationAdmin,
    payouts,
    contentManagement,
  ];

  const setTitles = () => {
    return (
      <Menu
        className="sidebar"
        style={{ background: "#fbfcfd" }}
        theme="light"
        selectedKeys={[selectedKeys]}
        mode="inline"
        items={items}
      />
    );
  };


  
  return (
    <>
      {documentData ? (
        <div className="logo-sidebar">
          <img src={"data:" + docType + ";base64," + documentData} alt="" />
        </div>
      ) : (
        <p style={{ color: "transparent", height: "50px" }}>logo</p>
      )}

      <div className="nav-panel" style={{'--bgColor': appColors?.appPrimaryLightColor ?? '' ,'--iconColor': appColors?.appPrimaryColor ?? ''}as any}>{setTitles()}</div>
    </>
  );
}
